import {
  GET_RECIPES,
  SET_SHOWRECIPES,
  SET_LOADING,
  CLEAR_RECIPES,
  SET_PAGE,
  SET_NAV,
  CLEAR_SEARCH,
  CLEAR_SHOWPAGE,
  SET_SIMILARPAGE,
  SET_DISEASES,
  SET_DISEASEPAGE,
} from "../types";

const recipeSearchReducer = (state, action) => {
  switch (action.type) {
    case GET_RECIPES:
      return {
        ...state,
        recipes: action.payload,
        loading: false,
        redirect: false,
        page: 1,
      };
    case SET_SHOWRECIPES:
      return {
        ...state,
        showRecipe: action.payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CLEAR_RECIPES:
      return {
        ...state,
        loading: true,
        recipes: [{}],
        redirect: false,
        search: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_NAV:
      return {
        ...state,
        nav: action.payload,
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_SIMILARPAGE:
      return {
        ...state,
        similarpage: action.payload,
      };
    case SET_DISEASES:
      return {
        ...state,
        showDiseases: action.payload,
      };
    case SET_DISEASEPAGE:
      return {
        ...state,
        diseasePage: action.payload,
      };
    case CLEAR_SHOWPAGE:
      return {
        ...state,
        [action.payload]: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default recipeSearchReducer;
