import React, { useContext, useEffect } from "react";
import DrugSearchForm from "./drugSearchForm/DrugSearchForm";
// import Diseases from "./drugSearchForm/Diseases";
import drugSearchContext from "../../contexts/drugSearch/drugSearchContext";

const DrugSearch = (props) => {
  const { nav, setNav, clearSearch } = useContext(drugSearchContext);

  useEffect(() => {
    clearSearch();
    // eslint-disable-next-line
  }, []);

  const onClick = (e) => {
    setNav(e.target.name);
  };

  return (
    <div className="m-5 mt-5">
      <div className="container">
        <ul className="nav nav-tabs mb-4">
          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a
              className={nav === "1" ? "nav-link active" : "nav-link"}
              name="1"
              onClick={onClick}
              href="#"
            >
              Drug Search
            </a>
          </li>
          {/* <li className="nav-item"> */}
            {/* eslint-disable-next-line */}
            {/* <a
              className={nav === "2" ? "nav-link active" : "nav-link"}
              name="2"
              onClick={onClick}
              href="#"
            >
              Diseases
            </a>
          </li> */}
        </ul>
        <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-8 search-form">
                {nav === "1" && <DrugSearchForm history={props.history} />}
                {/* {nav === "2" && <Diseases history={props.history} />} */}
            </div>
            <div className="col-md-3 display-md d-none d-md-block "></div>
        </div>
      </div>
    </div>
  );
};

export default DrugSearch;
