import React, { useContext, useLayoutEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from '../../contexts/auth/authContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated, loading} = useContext(AuthContext);
    const { loadUser } = useContext(AuthContext);


    useLayoutEffect(() => {
        loadUser();
        // eslint-disable-next-line
    }, [])

    return (
        <Route {...rest} render={props => {
            if (!isAuthenticated && !loading) {
                return <Redirect to='/login' />
            } else {
                return <Component {...props} />
            }
        }
        }
        />
    )
}

export default PrivateRoute