import React, {  useState, useContext } from 'react'
import searchContext from '../../../contexts/search/searchContext'
import Dropdown from './inputs/Dropdown'
import Multiselect from './inputs/Multiselect'
import ReactTooltip from "react-tooltip";

const Dravyaguna = props => {

    const { getPlant, clearPlants } = useContext(searchContext);

    const initialState = {
        plant_name: "",
        part_used: "",
        botanical_name: "",
        place: "",
        english: "",
        sanskrit: "",
        assamese: "",
        bengali: "",
        gujrati: "",
        hindi: "",
        kannada: "",
        kashmiri: "",
        malayalam: "",
        marathi: "",
        oriya: "",
        punjabi: "",
        tamil: "",
        telugu: "",
        urdu: "",
        chemical_constituents: [""],
        rasa: [""],
        guna: [""],
        virya: "",
        vipaka: "",
        karma: [""],
        important_formulations: "",
        therapeutic_uses: [""],
        chemical_constituents_bool: false,
        rasa_bool: false,
        guna_bool: false,
        karma_bool: false,
        therapeutic_uses_bool: false,
        dosha_karma: [""],
        dosha_karma_bool: false,
    }

    const [search, setSearch] = useState(initialState)

    const [isActive, setActive] = useState(false);

    // const { plant_name, part_used, botanical_name, place } = search;

    const onSubmit = (e) => {
        e.preventDefault();
        if(!isActive)
        {
            clearPlants(search);
            getPlant(search);
            props.history.push(`/search/result`)
        }

    }

    const context = {
        getPlant: getPlant,
        clearPlants: clearPlants,
        initialState: initialState
    }

    return (
        <form onSubmit={onSubmit} >
            <h1 className="display-4">Dravyaguna</h1>
            <div className="form-group">
                <label htmlFor="part_used"><i className="fas fa-question-circle text-primary" data-tip data-for="Guna"></i> Guna{' (Attributes) '}</label>
                <small id="emailHelp" className="d-inline ml-2 form-text text-muted">Select Multiple Values</small>
                <Multiselect placeholder={"Guna eg: Laghu"} name={"guna"} setSearch={setSearch} search={search} context={context}  active={{ isActive: isActive, setActive: setActive }} hide={false} />

            </div>
            <div className="form-group">
                <label htmlFor="part_used"><i className="fas fa-question-circle text-primary" data-tip data-for="Karma"></i> Karma{' (Action) '} </label>
                <small id="emailHelp" className="d-inline ml-2 form-text text-muted">Select Multiple Values</small>
                <Multiselect placeholder={"Karma eg: Mutrala"} name={"karma"} setSearch={setSearch} search={search} context={context}  active={{ isActive: isActive, setActive: setActive }} hide={false} />
            </div>
            <div className="form-group">
                <label htmlFor="rasa"><i className="fas fa-question-circle text-primary" data-tip data-for="Rasa"></i> Rasa{' (Taste) '}</label>
                <small id="emailHelp" className="d-inline ml-2 form-text text-muted">Select Multiple Values</small>
                <Multiselect placeholder={"Rasa eg: Madhura"} name={"rasa"} setSearch={setSearch} search={search} context={context}  active={{ isActive: isActive, setActive: setActive }} hide={false} />
            </div>
            <div className="form-group">
                <label htmlFor="part_used" className="d-block"><i className="fas fa-question-circle text-primary" data-tip data-for="Virya"></i> Virya{' (Potency) '}</label>
                <Dropdown name={"virya"} setSearch={setSearch} search={search} context={context}  active={{ isActive: isActive, setActive: setActive }} />
            </div>
            <div className="form-group">
                <label htmlFor="part_used" className="d-block"><i className="fas fa-question-circle text-primary" data-tip data-for="Vipaka"></i> Vipaka{' (Post-digestive effect) '}</label>
                <Dropdown name={"vipaka"} setSearch={setSearch} search={search} context={context}  active={{ isActive: isActive, setActive: setActive }} />
            </div>
            <button type="submit" className="btn btn-primary btn-block w-75 mt-4">Submit</button>


            <ReactTooltip id="Guna" place="right" effect="float" className="w-25">
                All substances like plants/herbs etc. have some Guna (attributes) that cause various effects in the body.
            </ReactTooltip>

            <ReactTooltip id="Rasa" place="right" effect="float" className="w-25">
                Rasa is the special sense perceived through the Rasna or Rasanendriya (tongue or taste buds). Rasa (Taste) is the direct action of a drug/medicinal plant on the nerve endings in the mucous membrane of the mouth.
            </ReactTooltip>

            <ReactTooltip id="Virya" place="right" effect="float" className="w-25">
                Virya means potency (Tasir) or active property and is derived from Sanskrit word "Vira Vikranto". It is a driving force (efficacy) behind the therapeutic activity of the dravya (substance) or drug.

            </ReactTooltip>

            <ReactTooltip id="Vipaka" place="right" effect="float" className="w-25">
                The taste essence produced after the digestion of a substance with the help of Jatharagni (digestive fire) is called Vipaka. It determines the specific action of an herb on Doshas.
            </ReactTooltip>

            <ReactTooltip id="Karma" place="right" effect="float" className="w-25">
                Substances affect the body in various ways, which is known as Karma (action).
            </ReactTooltip>
        </form>

    )
}

export default Dravyaguna