import React from 'react'

const Policy = () => {
    return (
        <div className="container my-4">

            <h1 className="display-4">
                PRIVACY POLICY
            </h1>

            PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING
            THE WEBSITE, YOU AGREE TO BE BOUND BY THE TERMS DESCRIBED HEREIN
            AND ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO
            ALL OF THESE TERMS, DO NOT USE THE WEBSITE.

            <br />
            <br />

            <strong className="mt-5">
                Context &amp; Purpose
            </strong>
            <div className="mb-2">
                The domain name www.ayurvedainformatics.com and https://cosylab.iiitd.edu.in/,
                an internet-based portal is owned and operated by Ayusla Private Limited, a company duly
                incorporated under the provisions of the Companies Act, 2013, having its CIN #
                U93090DL2017PTC322665 and with its registered office as, H-5, 3 rd Floor, Near Sanjay
                Park, Shakarpur, New Delhi, East Delhi, Delhi, 110092, India, (hereinafter, referred to as
                “Ayusla” or “We” or “Our” or “Us” or “Company”).
            </div>
            <div className="mb-2">
                The Website is a platform that primarily serves as information or knowledge portal. The
                website provides a searchable database of medicinal herbs, plants, kitchen ingredients,
                ayurvedic formulations and other Ayurveda Pharmacopoeia related data. The data of classical
                preparations are compiled directly from its original reference textbooks of Ayurveda and
                other original sources. Whereas the data of proprietary preparations are either sourced from
                literature provided by its seller or information provider.
            </div>
            <div className="mb-2">
                The website is categorized as Subscription services which may require the User to Subscribe
                by registering and creating a profile to access or use all of its features and content for a period
                of time that is stipulated by Ayurveda Informatics. The Company reserves the right to
                change, amend or modify the Subscription rates or duration or any policy(ies) or guideline(s)
                regarding the access to the Website or the terms of subscription, at any time and in its sole
                discretion.
            </div>
            <div className="mb-2">
                The website also facilitates the purchase of products sold by various relevant manufacturers
                (hereinafter referred to as “Services”). The arrangement between the Sellers and Ayusla shall
                be governed in accordance with this Terms of Use. The Services would be made available to
                such natural persons who have agreed to become buyers on the Website after obtaining due
                registration, in accordance with the procedure as determined by Ayusla, from time to time
                (referred to as “You” or “Your” or “Yourself” or “User”, which terms shall also include
                natural persons who are accessing the Website merely as visitors). The Services are offered to
                the Users through various modes which shall include issue of discount coupons and vouchers
                that can be redeemed for various goods/services offered for sale by the Sellers. To facilitate
                the relation between Sellers and the Users through the Website, Ayusla shall send to the
                Users promotional content including but not limited to emailers, notifications and messages.
            </div>
            <div className="mb-2">
                For the purpose of providing the Services and for other purposes identified in this Privacy
                Policy, Ayusla will be required to collect and host certain data and information of the Users.
                Ayusla is committed to protecting the personal information of the Users and takes all
                reasonable precautions for maintaining confidentiality of the User’s personal information.
                This Privacy Policy has been designed and developed to help you understand the following:
            </div>
            <div className="mb-2 ml-4">
                The type of personal information (including sensitive personal data or information) that
                Ayusla collects from the Users;
            </div>
            <div className="mb-2 ml-4">
                The purpose of collection, means and modes of usage of such personal information by
                Ayusla;
            </div>
            <div className="mb-2 ml-4">
                How and to whom Ayusla will disclose such information;

            </div>
            <div className="mb-2 ml-4">
                How Ayusla will protect the personal information including sensitive personal data or
                information that is collected from the Users; and
            </div>
            <div className="mb-2 ml-4">
                How User’s may access and/ or modify their personal information.
            </div>
            <div className="mb-2">
                This Privacy Policy shall apply to the use of the Website by all Users. Accordingly, a
                condition of each User&#39;s use of and access to the Website and to the other services provided
                by Ayusla to Users is their acceptance of this Privacy policy. Any User is required to read
                and understand the provisions set out herein prior to submitting any sensitive personal data or
                information to Ayusla, failing which they are required to leave the Website immediately.
            </div>
            <div className="mb-4">
                This Privacy Policy is published in compliance of the Information Technology Act, 2000 of
                India and the rules, regulations, guidelines and clarifications framed thereunder as amended
                from time to time, including the (Indian) Information Technology (Reasonable Security
                Practices and Procedures and Sensitive Personal Information) Rules, 2011.
            </div>

            <strong>
                <em>
                    What is personal information?
                </em>
            </strong>

            <p>

                Personal Information means any information that relates to a natural person, which, either
                directly or indirectly, in combination with other information available with Ayusla, is capable
                of identifying the person concerned.
            </p>
            <p>
                Sensitive Personal Data or Information means Personal Information of any individual relating
                to password; financial information such as UPI, bank account or credit card or debit card or
                other payment instrument details; any detail relating to the above as provided to or received
                by Ayusla for processing or storage. However, any data/ information relating to an individual
                that is freely available or accessible in public domain or furnished under the Right to
                Information Act, 2005 or any other law shall not qualify as sensitive personal
                data/information.
            </p>
            <p>
                Ayusla will collect and use information obtained from UPI, credit card, Debit Card and
                similar Payment options transactions only for purposes of processing your payment/fee for
                using our services.
            </p>
            <p>
                The UPI, Credit Card/Debit Card/Payment Gateway information provided by you to Ayusla
                will be stored in a confidential manner. Our staff or authorized volunteers may access such
                information only when there is an appropriate business reason to do so, such as when a
                refund must be issued back to the UPI ID, Credit Card/Debit Card/Bank. We maintain
                physical, electronic and procedural safeguards to protect your information, and our
                employees are required to follow these privacy standards.
            </p>

            <strong>
                <em>
                    Types of personal information collected by Ayusla:
                </em>
            </strong>

            <p>
                A User may have limited access to the Website and utilize some of the Services provided by
                Ayusla by creating an account on the Website. Registered Users can access some of the
                information and details available on the Website provided at its discretion by Ayusla.
            </p>
            <p>
                In order to have access to all the features and benefits on our Website using the ‘Professional
                Pack/Premium Subscription’, and for the purposes of accessing, interacting, exchanging
                information and transacting through the Website, the Users are required to obtain paid
                registration, in accordance with the procedure established by Ayusla in this regard, a user
                may be required to first create an account on our Website. As part of the registration process,
                Ayusla may collect the following personal information from the Users (hereinafter
                collectively referred to as “User Information”):
            </p>
            <p>
                Name;

            </p>
            <p>
                User ID;
            </p>
            <p>
                Email address;

            </p>
            <p>
                Phone number;

            </p>
            <p>
                Date of Birth;

            </p>
            <p>
                Profession;

            </p>
            <p>
                Gender;

            </p>
            <p>
                Organization;

            </p>
            <p>
                Address (including country and ZIP/ postal code);

            </p>
            <p>
                Password chosen by the User;

            </p>
            <p>
                Valid financial and bank account information; and

            </p>
            <p>
                Other details as the User may volunteer.

            </p>
            <p>
                Internet use: Ayusla may also receive and/or hold information about the User’s browsing
                history including the URL of the site that the User visited prior to visiting the website as well
                as the Internet Protocol (IP) address of each User&#39;s computer (or the proxy server a User used
                to access the World Wide Web), User&#39;s computer operating system and type of web browser
                the User is using as well as the name of User&#39;s ISP. The Website uses temporary cookies to
                store certain data (that is not sensitive personal data or information) that is used by Ayusla
                and its service providers for the technical administration of the Website, research and
                development, and for User administration.

            </p>
            <p>
                The Website may enable User to communicate with other Users or to post information to be
                accessed by others, whereupon other Users may collect such data. Ayusla hereby expressly
                disclaims any liability for any misuse of such information that is made available by visitors in
                such a manner.

            </p>
            <p>
                Ayusla does not knowingly collect personal data from people who are “incompetent to
                contract” including minors and people of unsound mind.

            </p>

            <p>
                Ayusla may in future include other optional requests for information from the User including
                through User Surveys in order to help Ayusla customize the Website to deliver personalized
                information to the User and for other purposes are mentioned herein. Such information may

                also be collected in the course of contests conducted by Ayusla. Any such additional personal
                information will also be processed in accordance with this Privacy Policy.

            </p>

            <strong>
                <em>
                    Purposes for which your information may be used by Ayusla:
                </em>
            </strong>

            <p>

                The personal information and other information which Ayusla collects from you may be
                utilized for various business and/or regulatory purposes including for the following purposes:
            </p>
            <p>
                Registration of the User on the Website;

            </p>
            <p>
                Processing the User’s orders / requests and provision of Services;

            </p>
            <p>
                Complete transactions with Users effectively and billing for the products/ Services provided;

            </p>
            <p>
                Technical administration and customization of Website;

            </p>
            <p>
                Delivery of personalized information and target advertisements to the User;

            </p>
            <p>
                Improvement of Services and features on the Website;

            </p>
            <p>
                Research and development and for User administration (including conducting User surveys)
                Non-personally identifiable information, exclusively owned by Ayusla may be used in an
                aggregated or non-personally identifiable form for research, statistical analysis and business
                intelligence purposes, and Ayusla may sell or otherwise transfer such research, statistical or
                intelligence data in an aggregated or non-personally identifiable form to third parties and
                affiliates.

            </p>
            <p>
                Dealing with requests, enquiries or complaints and other customer care related activities; and
                all other general administrative and business purposes.

            </p>

            <strong>
                <em>
                    Disclosure and transfer of your personal information:
                </em>
            </strong>

            <p>
                Ayusla may need to disclose/ transfer your personal information to the following third parties
                for the purposes mentioned in this Privacy Policy and the Terms of Use:

            </p>
            <p>
                To Sellers and other service providers appointed by Ayusla for the purpose of carrying out
                services on Ayusla’s behalf under contract. Generally, these contractors do not have any
                independent right to share this information, however certain contractors who provide services

            </p>
            <p>
                on the Website, including the providers of online communications services, will have rights
                to use and disclose the personal information collected in connection with the provision of
                these services in accordance with their own privacy policies.

            </p>
            <p>
                To our affiliates in India or in other countries who may use and disclose your information for
                the same purposes as us.
            </p>

            <strong>
                <em>
                    To government institutions/ authorities to the extent required:
                </em>
            </strong>

            <ol type="a">
                <li>
                    under the laws, rules, and regulations and or under orders of any relevant judicial or quasi-
                    judicial authority;
                </li>
                <li>
                    to protect and defend the rights or property of Ayusla;
                </li>
                <li>
                    to fight fraud and credit risk;
                </li>
                <li>
                    to enforce Ayusla&#39;s Terms of Use (to which this Privacy Policy is also a part); or
                </li>
                <li>
                    when Ayusla, in its sole discretion, deems it necessary in order to protect its rights or the
                    rights of others.
                </li>
            </ol>

            <p>
                If otherwise required by an order under any law for the time being in force including in
                response to enquiries by Government agencies for the purpose of verification of identity, or
                for prevention, detection, investigation including cyber incidents, prosecution, and
                punishment of offences.
            </p>
            <p>
                In case of contests conducted by Ayusla in which the User participates, the concerned User’s
                information may be disclosed to third parties, also be disclosed to third parties to the extent
                necessary for prize fulfilment and other aspects of such contest or similar offering.
            </p>
            <p>
                Ayusla makes all User Information accessible to its employees, associates and data
                processors only on a need-to-know basis. All Ayusla employees, associates and data
                processors, who have access to, and are associated with the processing of User Information,
                are obliged to respect its confidentiality.
            </p>
            <p>
                Non-personally identifiable information may be disclosed to third party ad servers, ad
                agencies, technology vendors and research firms to serve advertisements to the Users. Ayusla
                may also share its aggregate findings (not specific information) based on information relating
                to the User’s internet use to prospective, investors, strategic partners, sponsors and others in
                order to help growth of Ayusla&#39;s business.
            </p>
            <p>
                Ayusla may also disclose or transfer the User Information, to another third party as part of
                reorganization or a sale of the assets of Ayusla corporation division or company. Any third
                party to which Ayusla transfers or sells its assets will have the right to continue to use the
                personal data and/ or other information that a User provide to us.
            </p>

            <strong>
                <em>
                    Retention of the information:
                </em>
            </strong>

            <p>
                All the information collected/ stored under this Privacy Policy and Terms of Use is
                maintained by Ayusla in electronic form on its equipment, and on the equipment of its
                employees. User Information may also be converted to physical form from time to time.
                Regardless of the manner of storage, Ayusla will keep all User Information confidential and
                will use/ disclose it only the manner specified under the Privacy Policy and Terms of Use.
            </p>
            <p>
                Part of the functionality of the Website is assisting the Sellers to maintain and organize such
                information to effect sale and purchase of products. Ayusla may, therefore, retain and submit
                all such records to the appropriate authorities, or to Sellers who request access to such
                information.
            </p>
            <p>
                The Website is also designed for assisting the Users to access information relating to
                pharmaceutical products. Ayusla may, therefore, retain and submit all such records to the
                relevant Users.
            </p>
            <p>
                Ayusla will also ensure that User Information is not kept for a period longer than as required
                for the purposes for which it is collected or as required under any applicable law.
            </p>

            <strong>
                <em>
                    Links to third-party advertisements:
                </em>
            </strong>

            <p>
                The links to third-party advertisements, third party websites or any third-party electronic
                communication services (referred to as “Third Party Links”) may be provided on the Website
                which are operated by third parties and are not controlled by, or affiliated to, or associated
                with Ayusla unless expressly specified on the Website.
            </p>
            <p>
                If you link to any such Third-Party Links, we request you review the website’s privacy
                policy. Ayusla accepts no responsibility and liability for the policies or practices of Third-
                Party Links.

            </p>

            <strong>
                <em>
                    Security Practices and Procedures:
                </em>
            </strong>

            <p>
                Ayusla adopts reasonable security practices and procedures to include, technical, operational,
                managerial and physical security control measures in order to protect the personal
                information in its possession from loss, misuse and unauthorized access, disclosure, alteration
                and destruction.
            </p>
            <p>
                Ayusla makes all User Information accessible to its employees only on a need-to-know basis,
                and binds all such employees to strict confidentiality obligations.
            </p>
            <p>
                Ayusla takes adequate steps to ensure that third parties to whom the Users’ Sensitive Personal
                Data or Information may be transferred adopt reasonable level of security practices and
                procedures to ensure security of Personal Information.
            </p>

            <strong>
                <em>
                    Users’ rights in relation to their personal information collected by Ayusla:
                </em>
            </strong>
            <p>
                All the information provided to Ayusla by a User, including Sensitive Personal Information,
                is voluntary. User has the right to withdraw his/ her/ its consent at any time, in accordance
                with the terms of this Privacy Policy and the Terms of Use, but please note that withdrawal of
                consent will not be retroactive.
            </p>
            <p>
                Users can access, modify, correct and delete the personal information provided by them
                which has been voluntary given by the User and collected by Ayusla in accordance with this
                Privacy Policy and Terms of Use. However, if the User updates his/ her information, Ayusla
                may keep a copy of the information which User originally provided to Ayusla in its archives
                for uses documented herein. In case the User seeks to update or correct, his/ her personal
                information, the User may exercise these rights by emailing Ayusla at cafeayusla@gmail.com
                and communicate the change(s) for updating Ayusla’s records.
            </p>
            <p>
                If you, as a casual visitor, has inadvertently browsed any other pages of this Website prior to
                reading the Privacy Policy and the Terms of Use, and you do not agree with the manner in
                which such information is obtained, stored or used, merely quitting this browser application
                should ordinarily clear all temporary cookies installed by Ayusla. All visitors, however, are
                encouraged to use the &quot;clear cookies&quot; functionality of their browsers to ensure such clearing/
                deletion, as Ayusla cannot guarantee, predict or provide for the behaviour of the equipment of
                all the visitors of the Website.
            </p>
            <p>
                If you have inadvertently submitted any personal information to Ayusla prior to reading the
                Privacy Policy and Terms of Use, and you do not agree with the manner in which such
                information is collected, stored or used, then you can ask Ayusla, by sending an email to

                cafeayusla@gmail.com containing the rectification required, whether Ayusla is keeping
                personal data about him/ her, and every User is also entitled to require Ayusla to delete and
                destroy all such information relating to him/ her (but not other Users) in its possession.
            </p>
            <p>
                In case the User does not provide his/ her information or consent for usage of personal
                information or subsequently withdraws his/ her consent for usage of the personal information
                so collected, Ayusla reserves the right to discontinue the services for which the said
                information was sought.
            </p>

            <strong>
                <em>
                    Additional notes to the User:
                </em>
            </strong>
            <p>
                Ayusla does not exercise control over the sites displayed as search results or links from
                within its Services. These other sites may place their own cookies or other files on the Users&#39;
                computer, collect data or solicit personal information from the Users, for which Ayusla is not
                responsible or liable. Accordingly, Ayusla does not make any representations concerning the
                privacy practices or policies of such third parties or terms of use of such websites, nor does
                Ayusla guarantee the accuracy, integrity, or quality of the information, data, text, software,
                sound, photographs, graphics, videos, messages or other materials available on such websites.
                Ayusla encourages the User to read the privacy policies of that website.
            </p>
            <p>
                Ayusla shall not be responsible in any manner for the authenticity of the personal information
                or sensitive personal data or information supplied by the User to Ayusla or any Seller. If a
                User provides any information that is untrue, inaccurate, not current or incomplete (or
                becomes untrue, inaccurate, not current or incomplete), or Ayusla has reasonable grounds to
                suspect that such information is untrue, inaccurate, not current or incomplete, Ayusla has the
                right to suspend or terminate such account at its sole discretion.
            </p>
            <p>
                Ayusla shall not be responsible for any breach of security or for any actions of any third
                parties that receive Users&#39; personal data or events that are beyond the reasonable control of
                Ayusla including, acts of government, computer hacking, unauthorized access to computer
                data and storage device, computer crashes, breach of security and encryption, etc.
            </p>
            <p>
                The User is responsible for maintaining the confidentiality of the User&#39;s account access
                information and password. The User shall be responsible for all uses of the User&#39;s account
                and password, whether or not authorized by the User. The User shall immediately notify
                Ayusla of any actual or suspected unauthorized use of the User&#39;s account or password.
            </p>
            <p>
                Ayusla will communicate with the Users through email and notices posted on the Website or
                through other means available through the service, including text and other forms of
                messaging. The Users can ask Ayusla, by sending an email to cafeayusla@gmail.com
                containing the rectification required.
            </p>

            <strong>
                <em>
                    Changes in the Privacy Policy:
                </em>
            </strong>
            <p>
                Ayusla may update this privacy policy at any time, with or without advance notice. In the
                event there are significant changes in the way Ayusla treats User&#39;s personal information,
                Ayusla will either display a notice on the Website or send Users an email. If a User uses the
                Service after notice of changes have been sent to such User or published on the Website, such
                User hereby provides his/ her/ its consent to the changed practices.
            </p>


            <strong>
                <em>
                    Contact information:
                </em>
            </strong>

            <p>
                If any User has any grievance, comment, question or suggestion regarding any of our
                Services, please contact our customer service at cafeayusla@gmail.com
                If a User has any questions concerning Ayusla, the Website, this Agreement, or anything
                related to any of the foregoing, Ayusla can be reached at the following email address -
                cafeayusla@gmail.com or via the contact information available from the following hyperlink:
                Ayurveda Informatics (iiitd.edu.in)
            </p>
        </div>
    )
}

export default Policy
