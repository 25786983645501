import {
    CLEAR_ERRORS,
    USER_LOADED,
    AUTH_ERROR,
    AUTH_PRO_ERROR,
    LOGIN_USER
} from '../types'

const authReducer = (state, action) => {
    switch (action.type) {
        case USER_LOADED:
            const check = action.payload[0].user_type.toLowerCase() in {pro: 1, admin: 1} ? true : false;
            const check2 = action.payload[0].user_type.toLowerCase() in {admin: 1} ? true : false;
            return ({
                ...state,
                user: action.payload,
                isAuthenticated: true,
                pro: check,
                admin: check2,
                loading: false,
            })
        case LOGIN_USER:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                pro: false,
                admin: false, 
                loading: false
            }
        case AUTH_ERROR:
            localStorage.removeItem('token');
            delete localStorage.token;
            return {
                ...state,
                error: action.payload,
                isAuthenticated: false,
                pro: false,
                admin: false, 
                user: null,
                token: null,
                loading: false
            }

        case AUTH_PRO_ERROR:
            return {
                ...state,
                error: action.payload,
                pro: false,
                admin: false,
                loading: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export default authReducer;