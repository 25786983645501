import React, { useContext, useLayoutEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from '../../contexts/auth/authContext'

const AdminRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated, loading, admin } = useContext(AuthContext);
    const { loadUser } = useContext(AuthContext);

    useLayoutEffect(() => {

        loadUser();
        // eslint-disable-next-line
    }, [])
    return (
        <Route {...rest} render={props => {
            if (!loading) {
                if (!isAuthenticated) {
                    return <Redirect to='/login' />

                } else {
                    if (!admin) {
                        return <Redirect to='/' />
                    }
                    return <Component {...props} />

                }
            }
        }
        }
        />
    )
}

export default AdminRoute