import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import drugSearchContext from "../../../contexts/drugSearch/drugSearchContext";
import { Link } from "react-router-dom";

const DrugSearchItem = ({ drug, index }) => {

  const { setLoading } = useContext(drugSearchContext);

  const onClick = (e) => {
    setLoading(true);
  };

  const { name_of_medicine, category_of_medicine, therapeutic_indications } = drug;
  return (
    <Fragment>
      <tr>
        <th scope="row">{index}</th>
        <td>
          <Link className="" to={`/showDrug/${drug.s_no}`} onClick={onClick}>
            {name_of_medicine !== "" &&
              name_of_medicine.toUpperCase().replace(/\//g, ' / ')}
          </Link>
        </td>
        <td>{category_of_medicine}</td>
        <td>{therapeutic_indications.replace(/;$/, '').replace(/;/g, ', ')}</td>
      </tr>
    </Fragment>
  );
};

DrugSearchItem.propTypes = {
  drug: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default DrugSearchItem;
