import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import AuthContext from '../../../contexts/auth/authContext'
import axios from 'axios'

const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const __DEV__ = document.domain === 'localhost'

const PayButton = (props) => {

    const { user } = useContext(AuthContext);

    const displayRazorPay = async () => {

        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!res) {
            alert("We encountered some error");
            return;
        }

        const resdata = await axios.post("/order");

        var options = {
            "key": process.env.REACT_APP_RAZORPAY_KEY,
            "amount": resdata.data[0].order_detail.amount,
            "currency": resdata.data[0].order_detail.currency,
            "name": "Ayurveda Informatics",
            "description": "Subscription for advance features",
            "image": "",
            "order_id": resdata.data[0].order_detail.id,
            "handler": async (response) => {

                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }

                }

                try {
                    axios.post('/success', response, config);
                } catch (err) {
                    console.log(err.response)
                    alert(err + "An Error Encountered. Please contact Customer Support");
                }
                props.history.push("/")
            },
            "prefill": {
                "name": user[0].username,
                "email": user[0].email,
            },
            "theme": {
                "color": "007bff"
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <div>
            <button className="btn btn-primary mt-4" onClick={displayRazorPay}>Subscribe{' '}<i className="fas fa-crown"></i></button>
        </div>
    )
}

export default withRouter(PayButton)
