import React, { useContext, Fragment, useState, useLayoutEffect } from "react";
import { useParams, Link } from "react-router-dom";
import recipeSearchContext from "../../contexts/recipeSearch/recipeSearchContext";
import Spinner from "../layout/Spinner";

const ShowRecipe = (props) => {
  const { id } = useParams();
  const { showRecipe, loading, setShowRecipes, search } =
    useContext(recipeSearchContext);
  const [state, setState] = useState({
    bool: false,
    backgroundStyle: {},
    tab: "1",
  });

  useLayoutEffect(() => {
    setShowRecipes(id);
    // eslint-disable-next-line
  }, []);

  const descClick = (e) => {
    if (!state.bool) {
      setState({
        ...state,
        bool: true,
        backgroundStyle: {
          filter: "blur(3px)",
        },
      });
    } else {
      setState({
        ...state,
        bool: false,
        backgroundStyle: {},
      });
    }
  };

  const onClick = (e) => {
    props.history.goBack();
  };

  const onTab = (e, s) => {
    setState({
      ...state,
      tab: s,
    });
  };

  // const highlight = (text, name) => {
  //     if (name === "") return <span>{text}</span>
  //     const num = text.toLowerCase().indexOf(name.toLowerCase());
  //     if (num >= 0) {
  //         return (
  //             <span>
  //                 {text.slice(0, num)}
  //                 <mark>{text.slice(num, num + name.length)}</mark>
  //                 {text.slice(num + name.length, text.length)}
  //             </span>
  //         )
  //     } else {
  //         return <span>{text}</span>
  //     }
  // }

  const linkTagging = (field, list, route) => {
    return field
      .split(";")
      .map((word, index) => {
        var flag = false;
        if (Array.isArray(list)) {
          list.forEach((check) => {
            if (check === word.trim()) {
              flag = true;
            }
          });
          if (flag) {
            return (
              <Fragment key={index}>
                <Link
                  to={`/showRecipe/${route}/${word.trim().replace("/", "_")}`}
                >
                  <mark>{word}</mark>
                </Link>
                {index === field.split(";").length - 1 ? "" : ", "}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={index}>
                <Link
                  to={`/showRecipe/${route}/${word.trim().replace("/", "_")}`}
                >
                  {word}
                </Link>
                {index === field.split(";").length - 1 ? "" : ", "}
              </Fragment>
            );
          }
        } else {
          if (word.trim() === list) {
            return (
              <Fragment key={index}>
                <Link to={`/showRecipe/${route}/${word.trim()}`}>
                  <mark>{word}</mark>
                </Link>
                {index === field.split(",").length - 1 ? "" : ", "}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={index}>
                <Link to={`/showRecipe/${route}/${word.trim()}`}>{word}</Link>
                {index === field.split(";").length - 1 ? "" : ", "}
              </Fragment>
            );
          }
        }
      });
  };

  const { tab } = state;

  return loading || showRecipe === undefined || showRecipe === null ? (
    <Spinner />
  ) : (
    <Fragment>
      
      <div style={state.backgroundStyle}>
        <div className="mt-5 mx-5 mb-2">
          <div className="display-4 text-primary back-button" onClick={onClick}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <span className="text-center">
            <h1 className="display-3 mb-0">
              {/* {highlight(showRecipe.name_of_medicine, search.name_of_medicine)} */}
              {showRecipe.name_of_recipe.toUpperCase()}
            </h1>
            
          </span>
        </div>
        <div className="container">
          <hr className="w-25" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <ul className="nav justify-content-center mb-3">
                <li className="nav-item">
                  <span
                    className={
                      "btn " + (tab === "1" ? "text-dark" : "text-primary")
                    }
                    onClick={(e) => onTab(e, "1")}
                  >
                    Information
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={
                      "btn " + (tab === "2" ? "text-dark" : "text-primary")
                    }
                    onClick={(e) => onTab(e, "2")}
                  >
                    Instructions
                  </span>
                </li>
              </ul>
              {tab === "1" && (
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" style={{ width: "70%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Region</th>
                      <td>
                        {/* {showRecipe.category_of_medicine && 
                                                highlight(showRecipe.category_of_medicine, search.category_of_medicine)} */}
                        {showRecipe.cuisine}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Ingredients</th>
                      <td>
                        {/* {showRecipe.dispensing_pack_size &&
                                                highlight(showRecipe.dispensing_pack_size, search.dispensing_pack_size)} */}
                        {showRecipe.ingredients_raw}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Time (Minutes) </th>
                      <td>
                        {showRecipe.total_time}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {tab === "2" && (
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "100%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {showRecipe.instructions.split('\n').map((step, index) => (
          <tr key={index}>
            <td>{step}</td>
          </tr>
        ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="col-md-4 text-center order-first order-md-last">
              <img
                style={{ objectFit: "cover" }}
                id="show-image"
                className="img-thumbnail mx-auto d-block"
                src={
                  showRecipe.img_url === "-"
                    ? require("../../images/placeholder.jpg")
                    : showRecipe.img_url
                }
                alt="recipe"
              />
              {showRecipe.url !== "-" && (
                <a
                  className="d-block mt-2"
                  href={showRecipe.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {"Visit  "}
                  <i className="fas fa-external-link-alt"></i>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShowRecipe;
