import React, { Fragment, useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../contexts/auth/authContext";
import axios from "axios";

const Home = (props) => {
  const [feedback, setFeedback] = useState("");
  const [show, setShow] = useState(false);
  const { isAuthenticated, loading } = useContext(AuthContext);

  const onClick = () => {
    if (!isAuthenticated && !loading) {
      props.history.push("/login");
    }
    setShow(!show);
  };

  const onChange = (e) => {
    setFeedback(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    window.location.reload();

    if (feedback.length)
      await axios.post(
        "/backend/feedback",
        { feedback: feedback },
        config
      );
  };

  // eslint-disable-next-line
  return (
    <div className="text-center">
      <div
        className="text-right position-absolute m-2 w-25"
        style={{ left: "73%" }}
      >
        {show ? (
          <Fragment>
            <div className="form-group postion-absolute">
              <label
                className="font-weight-light"
                onClick={onClick}
                style={{ marginRight: "10px" }}
              >
                Help Us Improve
              </label>
              <textarea
                className="form-control"
                onChange={onChange}
                placeholder="Your feedback would really help us to build this platform better."
                rows="3"
              ></textarea>
              <button
                className="btn btn-secondary btn-sm btn-block mt-2"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          </Fragment>
        ) : (
          <Link to="#" onClick={onClick} className="font-italic text-secondary">
            Feedback
          </Link>
        )}
      </div>
      <div className="container pt-3 ">
        <div className="text-center  justify-content-center">
          <h5
            className="display-4 font-weight-normal mt-3"
            style={{ fontSize: "2.5rem" }}
          >
            Plants, Medicinal Herbs, Dietary Ingredients <br></br>and more from
            Ayurveda.
          </h5>

          <div className="row m-3 mb-4">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body font-size-lg">
                  Ayurveda for everyone and anywhere.
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>

        {/* -------------------------------------------------------------------------- */}
          <Link
            to="/search"
            className="btn btn-dark mb-4"
            style={{ width: "15%", margin: "0% 1% 0% 0%" }}
          >
            Ayurvedic Plants
          </Link>

          <Link
            to="/recipeSearch"
            className="btn btn-dark mb-4"
            style={{ width: "15%", margin: "0% 0% 0% 1%" }}
          >
            Recipes
          </Link>

          <Link
            to="/drugSearch"
            className="btn btn-dark mb-4"
            style={{ width: "15%", margin: "0% 0% 0% 1%" }}
          >
            Ayurvedic Drugs
          </Link>
        {/* -------------------------------------------------------------------------- */}
        
          <br />
        </div>
      </div>
      <img
        className=" img-fluid mb-4"
        src={require("../../images/banner.png")}
        alt="Ayurveda-Informatics"
      />
    </div>
  );
};

export default Home;
