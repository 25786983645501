import React, { useContext, useEffect } from "react";
import AuthContext from "../../contexts/auth/authContext";
import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialGoogle } from "reactjs-social-login";

const Login = (props) => {
  const { loginUser, error, clearErrors, isAuthenticated } =
    useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/");
    }
    clearErrors();
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const style_social = {
    width: "300px",
    height: "56px",
    background: "#2C3333",
    color: "#ffffff",
  };

  const style_social_active = {
    background: "#ffffff",
    color: "#2C3333",
  };

  return (
    <div className="text-center mt-3">
      <h1 className="display-3">Login</h1>
      <p className="mb-4" style={{ fontSize: "20px" }}>
        Login to Ayurveda Informatics
      </p>
      <div
        // className="mb-5 "
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoginSocialGoogle
          client_id={
            "666863999817-hblcq7pao7fivd0nvhmg3o5g0dggr9kv.apps.googleusercontent.com"
          }
          scope="openid profile email"
          // discoveryDocs="claims_supported"
          // access_type="offline"
          onResolve={({ provider, data }) => {
            loginUser({
              email: data["email"],
              username: data["given_name"] + " " + data["family_name"],
            });
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <GoogleLoginButton
            style={style_social}
            iconSize="35px"
            align="center"
            activeStyle={style_social_active}
          >
            <span>Login using Google</span>
          </GoogleLoginButton>
        </LoginSocialGoogle>
      </div>
    </div>
  );
};

export default Login;
