import React, { useState, useContext } from "react";
import searchContext from "../../../contexts/search/searchContext";
import Autocomplete from "./inputs/Autocomplete";
import DropdownPartUsed from "./inputs/DropdownPartUsed";

const PlantSearch = (props) => {
  const { getBasicPlant, clearPlants } = useContext(searchContext);

  const initialState = {
    plant_name: "",
    part_used: "",
    botanical_name: "",
    place: "",
    english: "",
    sanskrit: "",
    assamese: "",
    bengali: "",
    gujrati: "",
    hindi: "",
    kannada: "",
    kashmiri: "",
    malayalam: "",
    marathi: "",
    oriya: "",
    punjabi: "",
    tamil: "",
    telugu: "",
    urdu: "",
    chemical_constituents: [""],
    rasa: [""],
    guna: [""],
    virya: "",
    vipaka: "",
    karma: [""],
    important_formulations: "",
    therapeutic_uses: [""],
    chemical_constituents_bool: false,
    rasa_bool: false,
    guna_bool: false,
    karma_bool: false,
    therapeutic_uses_bool: false,
    dosha_karma: [""],
    dosha_karma_bool: false,
  };

  const [search, setSearch] = useState(initialState);

  const [isActive, setActive] = useState(false);

  // const { plant_name, part_used, botanical_name, place } = search;

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isActive) {
      clearPlants(search);
      await getBasicPlant(search);

      props.history.push("/search/basicResult");
    }
  };

  const context = {
    getPlant: getBasicPlant,
    clearPlants: clearPlants,
    initialState: initialState,
    pathname: "/search/basicResult",
  };

  return (
    <form onSubmit={onSubmit}>
      <h1 className="display-4">Plant</h1>
      <div className="form-group">
        <label htmlFor="botanical_name" className="d-block">
          Botanical Name
        </label>
        <Autocomplete
          placeholder={"Botanical Name eg: Cleome gynandra"}
          name={"botanical_name"}
          setSearch={setSearch}
          search={search}
          context={context}
          active={{ isActive: isActive, setActive: setActive }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="english" className="d-block">
          Plant Name (English)
        </label>
        <Autocomplete
          placeholder={"Plant Name (English) eg: Radish"}
          name={"english"}
          setSearch={setSearch}
          search={search}
          context={context}
          active={{ isActive: isActive, setActive: setActive }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="plant_name" className="d-block">
          Plant Name (Sanskrit)
        </label>
        <Autocomplete
          placeholder={"Plant Name (Sanskrit) eg: Ajagandha"}
          name={"plant_name"}
          setSearch={setSearch}
          search={search}
          context={context}
          active={{ isActive: isActive, setActive: setActive }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="part_used" className="d-block">
          Part Used
        </label>
        <DropdownPartUsed
          placeholder={"Part Used eg: Stem"}
          name={"part_used"}
          setSearch={setSearch}
          search={search}
          context={context}
          active={{ isActive: isActive, setActive: setActive }}
        />
      </div>
      <button type="submit" className="btn btn-primary btn-block w-75 mt-4">
        Submit
      </button>
    </form>
  );
};

export default PlantSearch;
