import React from "react";
import recipeSearchContext from "./recipeSearchContext";
import recipeSearchReducer from "./recipeSearchReducer";
import { useReducer } from "react";
import {
  GET_RECIPES,
  SET_SHOWRECIPES,
  SET_LOADING,
  CLEAR_RECIPES,
  SET_PAGE,
  SET_NAV,
  CLEAR_SEARCH,
  CLEAR_SHOWPAGE,
  SET_SIMILARPAGE,
  SET_DISEASES,
  SET_DISEASEPAGE,
} from "../types";
import axios from "axios";

const RecipeSearchState = (props) => {
  const initialSearch = {
    name_of_medicine: "",
    category_of_medicine: "",
    therapeutic_indications: [""],
    therapeutic_indications_bool: false,
  };

  const initialState = {
    recipes: [],
    showRecipe: {},
    loading: true,
    page: 1,
    nav: "1",
    search: initialSearch,
    showDiseases: null,
    diseasePage: 1,
    similarPage: 1,
    redirect: true,
  };

  const [state, dispatch] = useReducer(recipeSearchReducer, initialState);

  //   const getBasicRecipe = async (search) => {
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     const res = await axios.post(
  //       `/backend/basicrecipesearch`,
  //       search,
  //       config
  //     );
  //     dispatch({ type: GET_RECIPES, payload: res.data });
  //   };

  const getRecipe = async (search) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `/backend/recipeSearch`,
      search,
      config
    );
    dispatch({ type: GET_RECIPES, payload: res.data });
    return true;
  };

  const setShowRecipes = async (s_no) => {
    const res = await axios.get(`/backend/recipe/${s_no}`);
    dispatch({ type: SET_SHOWRECIPES, payload: res.data[0] });
  };

  const setDiseases = async (disease) => {
    clearSearch();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let json = JSON.parse(JSON.stringify(initialSearch));
    json.therapeutic_indications[0] = disease;
    const res = await axios.post(`/backend/recipeSearch`, json, config);
    dispatch({ type: SET_DISEASES, payload: res.data });
  };

  const clearShowPage = (page) =>
    dispatch({ type: CLEAR_SHOWPAGE, payload: page });

  const setLoading = (loading) =>
    dispatch({ type: SET_LOADING, payload: loading });

  const clearRecipes = (search) =>
    dispatch({ type: CLEAR_RECIPES, payload: search });

  const clearSearch = () =>
    dispatch({ type: CLEAR_SEARCH, payload: initialSearch });

  const setPage = (page) => dispatch({ type: SET_PAGE, payload: page });

  const setDiseasePage = (page) =>
    dispatch({ type: SET_DISEASEPAGE, payload: page });

  const setSimilarPage = (page) =>
    dispatch({ type: SET_SIMILARPAGE, payload: page });

  const setNav = (nav) => dispatch({ type: SET_NAV, payload: nav });

  return (
    <recipeSearchContext.Provider
      value={{
        recipes: state.recipes,
        showRecipe: state.showRecipe,
        loading: state.loading,
        page: state.page,
        nav: state.nav,
        search: state.search,
        showDiseases: state.showDiseases,
        diseasePage: state.diseasePage,
        similarPage: state.similarPage,
        redirect: state.redirect,
        getRecipe,
        setShowRecipes,
        setDiseases,
        clearShowPage,
        setLoading,
        clearRecipes,
        clearSearch,
        setPage,
        setDiseasePage,
        setSimilarPage,
        setNav,
      }}
    >
      {props.children}
    </recipeSearchContext.Provider>
  );
};

export default RecipeSearchState;
