import React, { Fragment, useEffect, useContext } from 'react'
import profileContext from '../../contexts/profile/profileContext'
import Spinner from '../layout/Spinner'
import Display from './Display'

const Profile = () => {

    const { getProfile, loading } = useContext(profileContext);

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line
    }, [])

    return (loading ? <Spinner /> :
        <Fragment>
            <Display />
        </Fragment>)

}

export default Profile