import Autosuggest from "react-autosuggest";
import React, { Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

class Autocomplete extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      list: [],
      suggestions: [],
    };
  }

  componentDidMount() {
    const retrieve = async () => {
      const res = await axios.get(
        `/backend/recipeSearch/autocomplete?col_name=${this.props.name}&value=`
      );
      this.setState({
        list: res.data.map((word) => word[0]),
      });
    };

    retrieve();
  }

  render() {
    const { value, suggestions } = this.state;

    const getSuggestions = (value) => {
      const inputValue = new RegExp(value.trim(), "gi");
      const inputLength = inputValue.length - 4;

      const temp =
        inputLength === 0
          ? []
          : this.state.list.filter((lang) => lang.match(inputValue));

      if (temp.length > 0) {
        this.props.active.setActive(true);
      }

      return temp;
    };

    const { name, setSearch, search } = this.props;
    const { getRecipe, clearRecipes, initialState } = this.props.context;

    const getSuggestionValue = (suggestion) => suggestion;

    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

    const onChange = (event, { newValue }) => {
      this.setState({
        value: newValue,
      });
      setSearch({
        ...search,
        [name]: newValue,
      });
    };

    const onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: getSuggestions(value),
      });
    };

    const onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: [],
      });
      setTimeout(() => {
        this.props.active.setActive(false);
      }, 0);
    };

    const onClick = (e) => {
      clearRecipes(search);
      initialState[name] = value;
      getRecipe(initialState);
    };

    const inputProps = {
      name,
      placeholder: this.props.placeholder,
      value,
      onChange: onChange,
      className: "form-control-sm w-75",
    };

    // Finally, render it!
    return (
      <Fragment>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          highlightFirstSuggestion={true}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <span className="">
          <Link
            to={
              this.props.context.pathname
                ? this.props.context.pathname
                : `/recipeSearch/result`
            }
            onClick={onClick}
            tabIndex="-1"
            className="btn btn-sm btn-secondary"
          >
            Go
          </Link>
        </span>
      </Fragment>
    );
  }
}

export default Autocomplete;