import React, { useContext, useEffect, Fragment } from 'react';
import { useParams, Link } from "react-router-dom";
import SearchContext from '../../contexts/search/searchContext';
import Spinner from "../layout/Spinner"
import SearchItem from '../search/searchResult/SearchItem'




const ShowAyurForm = () => {


    const { ayurform } = useParams();

    const { showAyurForm, clearShowPage, setAyurForm, clearSearch, ayurFormPage, setAyurFormPage, similarPage, setSimilarPage } = useContext(SearchContext);

    useEffect(() => {
        clearSearch();
        clearShowPage('showAyurForm');
        setAyurForm(ayurform);

        // eslint-disable-next-line
    }, [])

    const onNext = e => {
        setAyurFormPage(ayurFormPage + 1);
    }

    const onPrev = e => {
        setAyurFormPage(ayurFormPage - 1);
    }

    const onNextSimilar = e => {
        setSimilarPage(similarPage + 1);
    }

    const onPrevSimilar = e => {
        setSimilarPage(similarPage - 1);
    }


    return (
        showAyurForm === null || showAyurForm === undefined ? <Spinner /> : showAyurForm.length !== 1 ? <div className="text-center">
            <div className="text-center font-size-lg m-4">
                <h3 className="text-secondary m-0">Ayurvedic Formulation</h3>
                <h1 className="display-2 text-center">{ayurform}</h1>
            </div>
            <hr className="w-25" />
            <div className="container mb-5">
                <h4 className="font-weight-normal text-secondary text-center">Uses</h4>
                <div className="float-right mb-4 mx-4">
                    {ayurFormPage !== 1 && <button onClick={onPrev} className="btn btn-light"> Prev </button>}
                    <span className="pl-3 font-weight-bold">{`Page  ${ayurFormPage} `}</span><span className="pr-3">{"/ " + (parseInt((showAyurForm.length - 1) / 20) + (((showAyurForm.length - 1) % 20 === 0) ? 0 : 1)) + " "}</span>
                    {ayurFormPage !== (parseInt((showAyurForm.length - 1) / 20) + (((showAyurForm.length - 1) % 20 === 0) ? 0 : 1)) && <button onClick={onNext} className="btn btn-light"> Next </button>}
                </div>
                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{ width: "5%" }}>#</th>
                            <th scope="col" style={{ width: "25%" }}>Plant Name</th>
                            <th scope="col" style={{ width: "25%" }}>Botanical Name</th>
                            <th scope="col" style={{ width: "25%" }}>English Name</th>
                            <th scope="col" style={{ width: "20%" }}>Part Used</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showAyurForm.slice(0, showAyurForm.length - 1).slice((ayurFormPage - 1) * 20, ayurFormPage * 20).map((plant, index) =>
                            <SearchItem key={plant.s_no} index={index + 1 + (ayurFormPage - 1) * 20} plant={plant} />
                        )}
                    </tbody>
                </table>
                <hr className="m-5" />

                {showAyurForm[showAyurForm.length - 1].length !== 0 && <div>
                    <div className="mb-4 mx-4">
                        {similarPage !== 1 && <button onClick={onPrevSimilar} className="btn btn-light"> Prev </button>}
                        <span className="pl-3 font-weight-bold">{`Page  ${similarPage} `}</span><span className="pr-3">{"/ " + (parseInt(showAyurForm[showAyurForm.length - 1].length / 20) + ((showAyurForm[showAyurForm.length - 1].length % 20 === 0) ? 0 : 1)) + " "}</span>
                        {similarPage !== (parseInt(showAyurForm[showAyurForm.length - 1].length / 20) + ((showAyurForm[showAyurForm.length - 1].length % 20 === 0) ? 0 : 1)) && <button onClick={onNextSimilar} className="btn btn-light"> Next </button>}
                    </div>
                    <table className="table table-hover w-50 mx-auto">

                        <thead className="thead-dark">
                            <tr>
                                <th scope="col" style={{ width: "10%" }}>#</th>
                                <th scope="col" style={{ width: "50%" }}>Similar Ayurvedic Formulations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {showAyurForm[showAyurForm.length - 1].slice(0, showAyurForm[showAyurForm.length - 1].length).slice((similarPage - 1) * 20, similarPage * 20).map((ayurform, index) =>
                                <tr key={index}>
                                    <td>{index + 1 + (similarPage - 1) * 20}</td>
                                    <td><Link to={`/show/ayurform/${ayurform}`} >{ayurform}</Link></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>}

            </div>

        </div> : <Fragment>
                <div>
                    <h1 className="display-4 text-center mt-3">No Results Found</h1>
                </div>
            </Fragment>
    )
}

export default ShowAyurForm;