import React from 'react'
import Download from './Download'
import Upload from './Upload'

const Admin = () => {
    return (
        <div className="container mt-3">
            <div>
                <div className="mb-4">
                    <h4>
                        Upload
                    </h4>
                    <Upload link={'AyurTable'} />
                    <Upload link={'DrugTable'} />
                    <Upload link={'RecipeTable'} />
                    <Upload link={'FormuTable'} />
                </div>
                <div className="mb-4">
                    <h4>
                        Download
                    </h4>
                    <Download link={'AyurTable'} />
                    <Download link={'DrugTable'} />
                    <Download link={'RecipeTable'} />
                    <Download link={'FormuTable'} />
                    <Download link={'UsersTable'} />
                    <Download link={'FeedbackTable'} />
                    <Download link={'PaymentTable'} />
                </div>
            </div>
        </div>
    )
}

export default Admin
