import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../contexts/auth/authContext";

const Navbar = () => {
  const { logout, user, isAuthenticated, pro } = useContext(AuthContext);

  const onLogout = () => {
    logout();
  };

  const authLinks = (
    <Fragment>
      <li className="nav-item">
        <Link className="nav-link" onClick={onLogout} to="/">
          Logout
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link active" to="/profile">
          {user &&
            user[0].username
              .split(" ")
              .map((word) => word[0].toUpperCase() + word.slice(1, word.length))
              .join(" ")}{" "}
          <i className="fas fa-user-circle"></i>
        </Link>
      </li>
    </Fragment>
  );

  const guestLink = (
    <Fragment>
      <li className="nav-item">
        <Link className="nav-link" to="/login">
          Login
        </Link>
      </li>
    </Fragment>
  );

  return (
    <Fragment>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <div className="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item ">
              <Link className="nav-link" to={`/search`}>
                Plants
              </Link>
            </li>
            
            <li className="nav-item ">
              <Link className="nav-link" to={`/recipeSearch`}>
              Recipes
              </Link>
            </li>
            <li className="nav-item ">
              <Link className="nav-link" to={`/drugSearch`}>
                Drugs
              </Link>
            </li>

            <li className="nav-item ">
              <Link className="nav-link" to={`/about`}>
                About
              </Link>
            </li>
            {/* <li className="nav-item ">
                        <Link className="nav-link" to={`/howto`}>How to use</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className="nav-link" to={`/faq`}>FAQ</Link>
                    </li> 
            <li className="nav-item ">
              <Link className="nav-link" to={`/contact`}>
                Contact Us
              </Link>
            </li>
            <li className="nav-item ">
              <a
                className="nav-link"
                href="https://cosylab.iiitd.edu.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                CoSy Lab
              </a>
            </li>
            */}
          </ul>
        </div>
        <div className="mx-auto order-0">
          <Link className="navbar-brand mx-auto" to="/">
            <img
              alt=""
              src={require("../../images/ayurinfo_nobg.png")}
              className="pr-2"
              height="25px"
            />
            Ayurveda Informatics
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target=".dual-collapse2"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
          <ul className="navbar-nav ml-auto">
            {!pro && (
              <li className="nav-item">
                <Link className="nav-link active" to="/subscribe">
                  Subscribe <i className="fas fa-crown text-warning"></i>
                </Link>
              </li>
            )}
            {isAuthenticated !== null && (
              <Fragment>{isAuthenticated ? authLinks : guestLink}</Fragment>
            )}
          </ul>
        </div>
      </nav>
      {/* <nav className="navbar navbar-expand-lg navbar-dark bg-dark" id="nav">
                <Link className="navbar-brand" to={`/`}><i className="fas fa-leaf"></i> Ayurveda Informatics</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse " id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to={`/search`}>Search</Link>
                        </li>
                        <li className="nav-item ">
                            <Link className="nav-link" to={`/howto`}>How to use</Link>
                        </li>
                        <li className="nav-item ">
                            <Link className="nav-link" to={`/faq`}>FAQ</Link>
                        </li>
                        <li className="nav-item ">
                            <Link className="nav-link" to={`/contact`}>Contact Us</Link>
                        </li>
                        <li className="nav-item ">
                            <a className="nav-link" href="https://cosylab.iiitd.edu.in/" target="_blank" rel="noopener noreferrer">CoSy Lab</a>
                        </li>
                        <li className="nav-item ">
                            <Link className="nav-link" to="" onClick={onLogout}>Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </nav> */}
    </Fragment>
  );
};

export default Navbar;
