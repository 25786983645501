import React from "react";
import drugSearchContext from "./drugSearchContext";
import drugSearchReducer from "./drugSearchReducer";
import { useReducer } from "react";
import {
  GET_DRUGS,
  SET_SHOWDRUGS,
  SET_LOADING,
  CLEAR_DRUGS,
  SET_PAGE,
  SET_NAV,
  CLEAR_SEARCH,
  CLEAR_SHOWPAGE,
  SET_SIMILARPAGE,
  SET_DISEASES,
  SET_DISEASEPAGE,
} from "../types";
import axios from "axios";

const DrugSearchState = (props) => {
  const initialSearch = {
    name_of_medicine: "",
    category_of_medicine: "",
    therapeutic_indications: [""],
    therapeutic_indications_bool: false,
  };

  const initialState = {
    drugs: [],
    showDrug: {},
    loading: true,
    page: 1,
    nav: "1",
    search: initialSearch,
    showDiseases: null,
    diseasePage: 1,
    similarPage: 1,
    redirect: true,
  };

  const [state, dispatch] = useReducer(drugSearchReducer, initialState);

  //   const getBasicDrug = async (search) => {
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     const res = await axios.post(
  //       `/backend/basicdrugsearch`,
  //       search,
  //       config
  //     );
  //     dispatch({ type: GET_DRUGS, payload: res.data });
  //   };

  const getDrug = async (search) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `/backend/drugSearch`,
      search,
      config
    );
    dispatch({ type: GET_DRUGS, payload: res.data });
    return true;
  };

  const setShowDrugs = async (s_no) => {
    const res = await axios.get(`/backend/drug/${s_no}`);
    dispatch({ type: SET_SHOWDRUGS, payload: res.data[0] });
  };

  const setDiseases = async (disease) => {
    clearSearch();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let json = JSON.parse(JSON.stringify(initialSearch));
    json.therapeutic_indications[0] = disease;
    const res = await axios.post(`/backend/drugSearch`, json, config);
    dispatch({ type: SET_DISEASES, payload: res.data });
  };

  const clearShowPage = (page) =>
    dispatch({ type: CLEAR_SHOWPAGE, payload: page });

  const setLoading = (loading) =>
    dispatch({ type: SET_LOADING, payload: loading });

  const clearDrugs = (search) =>
    dispatch({ type: CLEAR_DRUGS, payload: search });

  const clearSearch = () =>
    dispatch({ type: CLEAR_SEARCH, payload: initialSearch });

  const setPage = (page) => dispatch({ type: SET_PAGE, payload: page });

  const setDiseasePage = (page) =>
    dispatch({ type: SET_DISEASEPAGE, payload: page });

  const setSimilarPage = (page) =>
    dispatch({ type: SET_SIMILARPAGE, payload: page });

  const setNav = (nav) => dispatch({ type: SET_NAV, payload: nav });

  return (
    <drugSearchContext.Provider
      value={{
        drugs: state.drugs,
        showDrug: state.showDrug,
        loading: state.loading,
        page: state.page,
        nav: state.nav,
        search: state.search,
        showDiseases: state.showDiseases,
        diseasePage: state.diseasePage,
        similarPage: state.similarPage,
        redirect: state.redirect,
        getDrug,
        setShowDrugs,
        setDiseases,
        clearShowPage,
        setLoading,
        clearDrugs,
        clearSearch,
        setPage,
        setDiseasePage,
        setSimilarPage,
        setNav,
      }}
    >
      {props.children}
    </drugSearchContext.Provider>
  );
};

export default DrugSearchState;
