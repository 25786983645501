import {
  GET_PLANT,
  SET_SHOW,
  SET_LOADING,
  CLEAR_PLANTS,
  SET_PAGE,
  SET_NAV,
  CLEAR_SEARCH,
  SET_KARMA,
  SET_AYURFORM,
  SET_DISEASES,
  CLEAR_SHOWPAGE,
  SET_DISEASEPAGE,
  SET_KARMAPAGE,
  SET_AYURFORMPAGE,
  SET_SIMILARPAGE,
} from "../types";

const searchReducer = (state, action) => {
  switch (action.type) {
    case GET_PLANT:
      return {
        ...state,
        plants: action.payload,
        loading: false,
        redirect: false,
        page: 1,
      };
    case SET_SHOW:
      return {
        ...state,
        show: action.payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CLEAR_PLANTS:
      return {
        ...state,
        loading: true,
        plants: [{}],
        redirect: false,
        search: action.payload,
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_DISEASEPAGE:
      return {
        ...state,
        diseasePage: action.payload,
      };
    case SET_KARMAPAGE:
      return {
        ...state,
        karmaPage: action.payload,
      };
    case SET_AYURFORMPAGE:
      return {
        ...state,
        ayurFormPage: action.payload,
      };
    case SET_SIMILARPAGE:
      return {
        ...state,
        similarPage: action.payload,
      };
    case SET_NAV:
      return {
        ...state,
        nav: action.payload,
      };
    case SET_KARMA:
      return {
        ...state,
        showKarma: action.payload,
      };
    case SET_DISEASES:
      return {
        ...state,
        showDiseases: action.payload,
      };
    case SET_AYURFORM:
      return {
        ...state,
        showAyurForm: action.payload,
      };
    case CLEAR_SHOWPAGE:
      return {
        ...state,
        [action.payload]: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default searchReducer;
