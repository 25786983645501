import React, { Fragment, useState, useContext } from "react";
import searchContext from "../../../contexts/search/searchContext";
import { Link } from "react-router-dom";
import Autocomplete from "./inputs/Autocomplete";
import DropdownPartUsed from "./inputs/DropdownPartUsed";
import Dropdown from "./inputs/Dropdown";
import Multiselect from "./inputs/Multiselect";
import ReactTooltip from "react-tooltip";

const SearchForm = (props) => {
  const { getPlant, clearPlants } = useContext(searchContext);

  const [more, setMore] = useState(false);

  const initialState = {
    plant_name: "",
    part_used: "",
    botanical_name: "",
    place: "",
    english: "",
    sanskrit: "",
    assamese: "",
    bengali: "",
    gujrati: "",
    hindi: "",
    kannada: "",
    kashmiri: "",
    malayalam: "",
    marathi: "",
    oriya: "",
    punjabi: "",
    tamil: "",
    telugu: "",
    urdu: "",
    chemical_constituents: [""],
    rasa: [""],
    guna: [""],
    virya: "",
    vipaka: "",
    karma: [""],
    important_formulations: "",
    therapeutic_uses: [""],
    chemical_constituents_bool: false,
    rasa_bool: false,
    guna_bool: false,
    karma_bool: false,
    therapeutic_uses_bool: false,
    dosha_karma: [""],
    dosha_karma_bool: false,
  };

  const [search, setSearch] = useState(initialState);

  const [isActive, setActive] = useState(false);

  // const { plant_name, part_used, botanical_name, place } = search;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isActive) {
      clearPlants(search);
      getPlant(search);
      props.history.push(`/search/result`);
    }
  };

  const onClick = (e) => {
    setMore(true);
  };

  const context = {
    getPlant: getPlant,
    clearPlants: clearPlants,
    initialState: initialState,
  };

  return (
    <form onSubmit={onSubmit}>
      <div>
        <div id="list-item-1" className="mb-4">
          <h1 className="font-weight-normal">Plant</h1>
          <div className="form-group">
            <label htmlFor="botanical_name" className="d-block">
              Botanical Name
            </label>
            <Autocomplete
              placeholder={"Botanical Name eg: Cleome gynandra"}
              name={"botanical_name"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="english" className="d-block">
              Plant Name (English)
            </label>
            <Autocomplete
              placeholder={"Plant Name (English) eg: Radish"}
              name={"english"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="plant_name" className="d-block">
              Plant Name (Sanskrit)
            </label>
            <Autocomplete
              placeholder={"Plant Name (Sanskrit) eg: Ajagandha"}
              name={"plant_name"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="part_used" className="d-block">
              Part Used
            </label>
            <DropdownPartUsed
              placeholder={"Part Used eg: Stem"}
              name={"part_used"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="part_used" className="d-block">
              Distribution and Habitat
            </label>
            <Autocomplete
              placeholder={"Place Found eg: Rajasthan"}
              name={"place"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
        </div>
        <div id="list-item-2" className="mb-4">
          <h1 className="font-weight-normal">Common Names</h1>
          <div className="form-group">
            <label htmlFor="part_used" className="d-block">
              Hindi
            </label>
            <Autocomplete
              placeholder={"Hindi eg: Amla"}
              name={"hindi"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="part_used" className="d-block">
              Sanskrit
            </label>
            <Autocomplete
              placeholder={"Sanskrit eg: Arunna"}
              name={"sanskrit"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
          {!more && (
            <Link to="#" className="d-block" onClick={onClick}>
              Search using more languages
            </Link>
          )}
          {more && (
            <Fragment>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Assamese
                </label>
                <Autocomplete
                  placeholder={"Assamese eg: Amlaku"}
                  name={"assamese"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Bengali
                </label>
                <Autocomplete
                  placeholder={"Bengali eg: Akanda"}
                  name={"bengali"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Gujrati
                </label>
                <Autocomplete
                  placeholder={"Gujrati eg: Ambala"}
                  name={"gujrati"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Kannada
                </label>
                <Autocomplete
                  placeholder={"Kannada eg: Aragvadha"}
                  name={"kannada"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Kashmiri
                </label>
                <Autocomplete
                  placeholder={"Kashmiri eg: Amli"}
                  name={"kashmiri"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Malayalam
                </label>
                <Autocomplete
                  placeholder={"Malayalam eg: Asokam"}
                  name={"malayalam"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Marathi
                </label>
                <Autocomplete
                  placeholder={"Marathi eg: Ajmoda"}
                  name={"marathi"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Oriya
                </label>
                <Autocomplete
                  placeholder={"Oriya eg: Ainla"}
                  name={"oriya"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Punjabi
                </label>
                <Autocomplete
                  placeholder={"Punjabi eg: Aula"}
                  name={"punjabi"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Tamil
                </label>
                <Autocomplete
                  placeholder={"Tamil eg: Asogam"}
                  name={"tamil"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Telegu
                </label>
                <Autocomplete
                  placeholder={"Telegu eg: Avisa"}
                  name={"telugu"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="part_used" className="d-block">
                  Urdu
                </label>
                <Autocomplete
                  placeholder={"Urdu eg: Ajmod"}
                  name={"urdu"}
                  setSearch={setSearch}
                  search={search}
                  context={context}
                  active={{ isActive: isActive, setActive: setActive }}
                />
              </div>
            </Fragment>
          )}
        </div>
        <div id="list-item-3" className="mb-4">
          <h1 className="font-weight-normal">Dravyaguna</h1>
          <div className="form-group">
            <label htmlFor="part_used">
              <i
                className="fas fa-question-circle text-primary"
                data-tip
                data-for="Guna"
              ></i>{" "}
              Guna{" (Attributes) "}
            </label>
            <small
              id="emailHelp"
              className="d-inline ml-2 form-text text-muted"
            >
              Select Multiple Values
            </small>
            <Multiselect
              placeholder={"Guna eg: Laghu"}
              name={"guna"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
              hide={true}
            />
          </div>
          <div className="form-group">
            <label htmlFor="part_used">
              <i
                className="fas fa-question-circle text-primary"
                data-tip
                data-for="Karma"
              ></i>{" "}
              Karma{" (Action) "}{" "}
            </label>
            <small
              id="emailHelp"
              className="d-inline ml-2 form-text text-muted"
            >
              Select Multiple Values
            </small>
            <Multiselect
              placeholder={"Karma eg: Mutrala"}
              name={"karma"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
              hide={true}
            />
          </div>
          <div className="form-group">
            <label htmlFor="rasa">
              <i
                className="fas fa-question-circle text-primary"
                data-tip
                data-for="Rasa"
              ></i>{" "}
              Rasa{" (Taste) "}
            </label>
            <small
              id="emailHelp"
              className="d-inline ml-2 form-text text-muted"
            >
              Select Multiple Values
            </small>
            <Multiselect
              placeholder={"Rasa eg: Madhura"}
              name={"rasa"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
              hide={true}
            />
          </div>
          <div className="form-group">
            <label htmlFor="part_used" className="d-block">
              <i
                className="fas fa-question-circle text-primary"
                data-tip
                data-for="Virya"
              ></i>{" "}
              Virya{" (Potency) "}
            </label>
            <Dropdown
              name={"virya"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="part_used" className="d-block">
              <i
                className="fas fa-question-circle text-primary"
                data-tip
                data-for="Vipaka"
              ></i>{" "}
              Vipaka{" (Post-digestive effect) "}
            </label>
            <Dropdown
              name={"vipaka"}
              setSearch={setSearch}
              search={search}
              context={context}
              active={{ isActive: isActive, setActive: setActive }}
            />
          </div>
          <ReactTooltip id="Guna" place="right" effect="float" className="w-25">
            All substances like plants/herbs etc. have some Guna (attributes)
            that cause various effects in the body.
          </ReactTooltip>

          <ReactTooltip id="Rasa" place="right" effect="float" className="w-25">
            Rasa is the special sense perceived through the Rasna or
            Rasanendriya (tongue or taste buds). Rasa (Taste) is the direct
            action of a drug/medicinal plant on the nerve endings in the mucous
            membrane of the mouth.
          </ReactTooltip>

          <ReactTooltip
            id="Virya"
            place="right"
            effect="float"
            className="w-25"
          >
            Virya means potency (Tasir) or active property and is derived from
            Sanskrit word "Vira Vikranto". It is a driving force (efficacy)
            behind the therapeutic activity of the dravya (substance) or drug.
          </ReactTooltip>

          <ReactTooltip
            id="Vipaka"
            place="right"
            effect="float"
            className="w-25"
          >
            The taste essence produced after the digestion of a substance with
            the help of Jatharagni (digestive fire) is called Vipaka. It
            determines the specific action of an herb on Doshas.
          </ReactTooltip>

          <ReactTooltip
            id="Karma"
            place="right"
            effect="float"
            className="w-25"
          >
            Substances affect the body in various ways, which is known as Karma
            (action).
          </ReactTooltip>
        </div>
        <div id="list-item-4" className="mb-4">
          <h1 className="font-weight-normal">Diseases</h1>
          <small id="emailHelp" className="d-inline form-text text-muted">
            Select Multiple Values
          </small>
          <Multiselect
            placeholder={"Diseases eg: Prameha"}
            name={"therapeutic_uses"}
            setSearch={setSearch}
            search={search}
            context={context}
            active={{ isActive: isActive, setActive: setActive }}
            hide={true}
          />
        </div>
        <div id="list-item-5" className="mb-4">
          <h1 className="font-weight-normal">Chemical Constituents</h1>
          <small id="emailHelp" className="d-inline form-text text-muted">
            Select Multiple Values
          </small>
          <Multiselect
            placeholder={"Chemical Constituents eg: Diastase"}
            name={"chemical_constituents"}
            setSearch={setSearch}
            search={search}
            context={context}
            active={{ isActive: isActive, setActive: setActive }}
            hide={true}
          />
        </div>
        <div id="list-item-6" className="mb-4">
          <h1 className="font-weight-normal">Ayurvedic Formulations</h1>
          <Autocomplete
            placeholder={"Ayurvedic Formulations eg: Narayana Churna"}
            name={"important_formulations"}
            setSearch={setSearch}
            search={search}
            context={context}
            active={{ isActive: isActive, setActive: setActive }}
          />
        </div>
      </div>

      <br />
      <button type="submit" className="btn btn-primary btn-block w-75 mt-4">
        Submit
      </button>
    </form>
  );
};

export default SearchForm;
