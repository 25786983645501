import React, { useContext } from "react";
import PayButton from "./PayButton.js";
import AuthContext from "../../../contexts/auth/authContext";

const Subscribe = () => {
  const { user, pro } = useContext(AuthContext);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div>
      {!pro ? (
        <div>
          <div className="container text-center mt-4">
            <div className="display-4">Subscribe to get full access</div>
            {user && user[0].user_type === "Pro" && (
              <div className="font-size-lg mt-4">
                Your Subscription ends on{" "}
                {user[0].expiry_date.slice(8, 10) +
                  " " +
                  months[parseInt(user[0].expiry_date.slice(5, 7)) - 1] +
                  " " +
                  user[0].expiry_date.slice(0, 4)}
              </div>
            )}
            <PayButton />
            <div className="row mt-4">
              <div className="col-lg-2"></div>
              <div className="col-lg-4">
                <div
                  className="card text-center mx-auto"
                  style={{ width: "14rem" }}
                >
                  <div className="card-body">
                    <h5 className="card-title mb-0">Basic</h5>
                    <p className="card-text mb-2">Limited Access</p>
                    <div>
                      <span className="card-text display-3 pr-2">0</span>
                      <i className="fas fa-rupee-sign font-size-lg"></i>
                      <p className="card-text mb-2">/month</p>
                    </div>
                  </div>
                </div>
                <table className="table mt-4">
                  <tbody>
                    <tr>
                      <td>Look up Plant Search Section</td>
                    </tr>
                    <tr>
                      <td>Customers Support</td>
                    </tr>
                    <tr>
                      <td>Free Updates </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-times"></i>
                        <br />
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-4">
                <div
                  className="card text-center mx-auto"
                  style={{ width: "14rem" }}
                >
                  <div className="card-body">
                    <h5 className="card-title mb-0">Advanced</h5>
                    <p className="card-text mb-2">Full Access</p>
                    <div>
                      <span className="card-text display-3 pr-2">299</span>
                      <i className="fas fa-rupee-sign font-size-lg"></i>
                      <p className="card-text mb-2">/month</p>
                    </div>
                  </div>
                </div>
                <table className="table mt-4">
                  <tbody>
                    <tr>
                      <td>Look up Plant Search Section</td>
                    </tr>
                    <tr>
                      <td>Customers Support</td>
                    </tr>
                    <tr>
                      <td>Free Updates</td>
                    </tr>
                    <tr>
                      <td>Unlock multi-search</td>
                    </tr>
                    <tr>
                      <td>
                        Get shortlisted rankwise plants/ingredients as per
                        multi-search
                      </td>
                    </tr>
                    <tr>
                      <td>Unrestricted access to the entire database</td>
                    </tr>
                    <tr>
                      <td>Access Dravyaguna section</td>
                    </tr>
                    <tr>
                      <td>Access Chemical Constituents Section</td>
                    </tr>
                    <tr>
                      <td>Access Diseases Section</td>
                    </tr>
                    <tr>
                      <td>Access Ayurvedic Formulations Section</td>
                    </tr>
                    <tr>
                      <td>Access Advanced segments </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
        </div>
      ) : (
        <h1 className="display-3 text-center">You are already a pro user!</h1>
      )}
    </div>
  );
};

export default Subscribe;
