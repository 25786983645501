import React, { useContext, useLayoutEffect } from 'react';
import { useParams } from "react-router-dom";
import SearchContext from '../../contexts/search/searchContext';
import Spinner from "../layout/Spinner"
import SearchItem from '../search/searchResult/SearchItem'




const ShowKarma = () => {


    const { karma } = useParams()

    const { showKarma, clearShowPage, setKarma, clearSearch, karmaPage, setKarmaPage } = useContext(SearchContext);

    useLayoutEffect(() => {
        clearSearch();
        clearShowPage('showKarma')
        setKarma(karma);
        // eslint-disable-next-line
    }, [])

    const onNext = e => {
        setKarmaPage(karmaPage + 1);
    }

    const onPrev = e => {
        setKarmaPage(karmaPage - 1);
    }

    return (
        showKarma === null ? <Spinner /> : <div className="container">

            <div className="text-center font-size-lg m-4">
                <h3 className="text-secondary m-0">Karma</h3>
                <h1 className="display-2 text-center">{karma}</h1>

            </div>
            <hr className="w-25" />
            <div className="container mb-5 text-center">
                
                <h4 className="font-weight-normal text-secondary text-center">Is Associated With</h4>
                <div className="float-right mb-4 mx-4">
                    {karmaPage !== 1 && <button onClick={onPrev} className="btn btn-light"> Prev </button>}
                    <span className="pl-3 font-weight-bold">{`Page  ${karmaPage} `}</span><span className="pr-3">{"/ " + (parseInt(showKarma.length / 20) + ((showKarma.length%20 === 0)?0:1)) + " "}</span>
                    {karmaPage !== (parseInt(showKarma.length / 20) + ((showKarma.length%20 === 0)?0:1)) && <button onClick={onNext} className="btn btn-light"> Next </button>}
                </div>
                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{ width: "5%" }}>#</th>
                            <th scope="col" style={{ width: "20%" }}>Plant Name</th>
                            <th scope="col" style={{ width: "20%" }}>Botanical Name</th>
                            <th scope="col" style={{ width: "15%" }}>Part Used</th>
                            <th scope="col" style={{ width: "40%" }}>Distribution and Habitat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showKarma.slice((karmaPage - 1) * 20, karmaPage * 20).map((plant, index) =>
                            <SearchItem key={plant.s_no} index={index + 1 + (karmaPage - 1) * 20} plant={plant} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ShowKarma;