import React, { useState } from "react";
import axios from "axios";

const Upload = ({ link }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      console.log(selectedFile);
      axios
        .post(`/backend/upload/${link}`, formData)
        .then((response) => {
          // Add any additional logic or state updates here
          const message = `Upload successful!\n\nResponse data:\n${JSON.stringify(
            response.data
          )}`;
          window.alert(message);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Upload failed:", error);
          // Handle error states or display an error message
        });
    }
  };

  const fileData = () => {
    if (selectedFile) {
      return (
        <div className="card w-lg-25 mb-4 float-right">
          <div className="card-body">
            <div>
              <h4>File to Upload</h4>
              <p>File Name: {selectedFile.name}</p>
              <p>File Type: {selectedFile.type}</p>
              <p>
              Last Modified: {new Date(selectedFile.lastModified).toDateString()}
              </p>
              <button className="btn btn-primary btn-sm" onClick={onFileUpload}>
                Upload!
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="w-25">
        <span className="mr-4"></span>
        <label
          htmlFor={`upload-${link}`}
          className="btn btn-primary btn m-0 mt-3 mb-3"
        >
          {link}
        </label>
        <input
          className="d-none"
          id={`upload-${link}`}
          type="file"
          onChange={onFileChange}
        />
      </div>
      {fileData()}
    </div>
  );
};

export default Upload;
