import React, { useContext, useLayoutEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SearchContext from "../../../contexts/search/searchContext";
import SearchItem from "./SearchItem";
import Filter from "../../layout/Filter";
import Spinner from "../../layout/Spinner";
import { result } from "./type";

const SearchResults = () => {
  const { plants, loading, redirect, page, setPage } =
    useContext(SearchContext);

  const history = useHistory();

  const location = useLocation();

  const goBack = () => {
    history.push("/search");
  };

  useLayoutEffect(() => {
    if (redirect) goBack();
    // eslint-disable-next-line
  }, []);

  const onNext = (e) => {
    setPage(page + 1);
  };

  const onPrev = (e) => {
    setPage(page - 1);
  };

  if (sessionStorage.length === 0 || result.path_key !== location.key) {
    result.result_plants = plants;
    result.path_key = location.key;
    sessionStorage.clear();
  }

  function handleUpdate(names) {
    result.result_plants = [];
    if (names !== null) {
      result.result_plants = plants.filter((plant) =>
        names.includes(plant.culinary_category)
      );
      setPage(1);
    }
    if (names.length === 0) {
      result.result_plants = plants;
      sessionStorage.clear();
    }
  }

  if (plants.length === 0 || plants[0] === undefined) {
    return (
      <Fragment>
        <div>
          <h1 className="display-4 text-center mt-3">No Results Found</h1>
        </div>
      </Fragment>
    );
  } else {
    return loading ? (
      <Spinner />
    ) : (
      <div className="container mt-3">
        <div className="float-right m-4 pt-3">
          {page !== 1 && (
            <button onClick={onPrev} className="btn btn-light">
              {" "}
              Prev{" "}
            </button>
          )}
          <span className="pl-3 font-weight-bold">{`Page  ${page} `}</span>
          <span className="pr-3">
            {"/ " +
              (parseInt(result.result_plants.length / 20) +
                (result.result_plants.length % 20 === 0 ? 1 : 1)) +
              " "}
          </span>
          {page !==
            parseInt(result.result_plants.length / 20) +
              (result.result_plants.length % 20 === 0 ? 1 : 1) && (
            <button onClick={onNext} className="btn btn-light">
              {" "}
              Next{" "}
            </button>
          )}
        </div>
        <div className="float-right m-4 pt-3">
          <Filter onChange={handleUpdate} />
        </div>
        <h1 className="display-3 ml-3">Results</h1>
        <table className="table table-hover text-center">
          <thead className="thead-dark">
            <tr>
              <th scope="col" style={{ width: "5%" }}>
                #
              </th>
              <th scope="col" style={{ width: "25%" }}>
                Plant Name
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Botanical Name
              </th>
              <th scope="col" style={{ width: "20%" }}>
                English Name
              </th>
              <th scope="col" style={{ width: "15%" }}>
                Part Used
              </th>
              <th scope="col" style={{ width: "10%" }}>
                Details
              </th>
              <th scope="col" style={{ width: "5%" }}>
                Score
              </th>
            </tr>
          </thead>
          <tbody>
            {result.result_plants
              .slice((page - 1) * 20, page * 20)
              .map((plant, index) => (
                <SearchItem
                  key={plant.s_no}
                  index={index + 1 + (page - 1) * 20}
                  plant={plant}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export default SearchResults;
