import React, { useState, useRef, useEffect } from "react";
import { checkboxes } from './Checkbox'

export default function App(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});


    const dropdownRef = useRef(undefined);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isDropdownClick =
                dropdownRef.current && dropdownRef.current.contains(event.target);

            if (isDropdownClick) {
                return;
            }
            setIsOpen(false);
        };

        document.addEventListener("mousedown", handleClickOutside); // handle desktops

        return () => {
            document.removeEventListener("mousedown", handleClickOutside); // handle desktops
        };
    }, [dropdownRef]);

 
    
    function handleApply() {

        setIsOpen(false);

        let names = [];
        let checks = document.getElementsByClassName('filter-checks');
        for (let i = 0; i < checks.length; i++) {
            if (checks[i].checked === true) {
                names.push(checks[i].name);
                // sessionStorage.setItem(checks[i].name, true);
            }
        }  
        props.onChange(names);
    }
 
    const handleChange = (event) => {
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: event.target.checked
        });
        
        if(event.target.checked === false) sessionStorage.removeItem(event.target.name);
        
        if(event.target.checked === true) sessionStorage.setItem(event.target.name, true);

    }
    

    return (
        <div className="filter-container" ref={dropdownRef}>
            <button onClick={()=> { setIsOpen(!isOpen);}} className="filter-button-component">
                Filter
            </button>
            {isOpen && (
                <div className="filter-dropdown">
                    <div className="filter-checkboxes">
                    <p>Culinary Categories</p>
                    <div style={{ borderTop: "1px solid #000 ", marginLeft: 20, marginRight: 20 }}></div>
                        <ul>
                            {checkboxes.map((item) => (
                                <li key={item.key}>
                                    <div className = "filter-input">
                                    <input
                                        type="checkbox"
                                        name={item.name}
                                        className="filter-checks"
                                        value={item.v}
                                        defaultChecked={sessionStorage.getItem(item.name)}
                                        onChange={handleChange}
                                    />
                                    <label>{item.label}</label>
                                    </div>
                                </li>
                            ))}

                        </ul>
                    </div>
                    <button onClick={handleApply} className="filter-apply-button">
                        Apply
                    </button>
                </div>
            )}
        </div>
    );
}
