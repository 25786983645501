import React from 'react';
import SearchState from './contexts/search/SearchState'
import { BrowserRouter as Router } from 'react-router-dom'
import AuthState from './contexts/auth/AuthState'
import setAuthToken from './utils/setAuthToken'
import AppLoadUser from './AppLoadUser'
import ProfileState from './contexts/profile/ProfileState'
import DrugSearchState from './contexts/drugSearch/DrugSearchState';
import RecipeSearchState from './contexts/recipeSearch/RecipeSearchState';


if (localStorage.token) {
    setAuthToken(localStorage.token);
}


function App() {
    return (
        <SearchState>
            <RecipeSearchState>
                <DrugSearchState>
                    <AuthState>
                        <ProfileState>
                            <Router basename={`/`}>
                                <AppLoadUser />
                            </Router>
                        </ProfileState>
                    </AuthState>
                </DrugSearchState>
            </RecipeSearchState>
        </SearchState>

    );
}

export default App;
