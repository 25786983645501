import React, { useContext, Fragment, useState, useLayoutEffect } from "react";
import { useParams, Link } from "react-router-dom";
import drugSearchContext from "../../contexts/drugSearch/drugSearchContext";
import Spinner from "../layout/Spinner";

const ShowDrug = (props) => {
  const { id } = useParams();
  const { showDrug, loading, setShowDrugs, search } =
    useContext(drugSearchContext);
  const [state, setState] = useState({
    bool: false,
    backgroundStyle: {},
    tab: "1",
  });

  useLayoutEffect(() => {
    setShowDrugs(id);
    // eslint-disable-next-line
  }, []);

  const descClick = (e) => {
    if (!state.bool) {
      setState({
        ...state,
        bool: true,
        backgroundStyle: {
          filter: "blur(3px)",
        },
      });
    } else {
      setState({
        ...state,
        bool: false,
        backgroundStyle: {},
      });
    }
  };

  const onClick = (e) => {
    props.history.goBack();
  };

  const onTab = (e, s) => {
    setState({
      ...state,
      tab: s,
    });
  };

  // const highlight = (text, name) => {
  //     if (name === "") return <span>{text}</span>
  //     const num = text.toLowerCase().indexOf(name.toLowerCase());
  //     if (num >= 0) {
  //         return (
  //             <span>
  //                 {text.slice(0, num)}
  //                 <mark>{text.slice(num, num + name.length)}</mark>
  //                 {text.slice(num + name.length, text.length)}
  //             </span>
  //         )
  //     } else {
  //         return <span>{text}</span>
  //     }
  // }

  const linkTagging = (field, list, route) => {
    return field
      .split(";")
      .map((word, index) => {
        var flag = false;
        if (Array.isArray(list)) {
          list.forEach((check) => {
            if (check === word.trim()) {
              flag = true;
            }
          });
          if (flag) {
            return (
              <Fragment key={index}>
                <Link
                  to={`/showDrug/${route}/${word.trim().replace("/", "_")}`}
                >
                  <mark>{word}</mark>
                </Link>
                {index === field.split(";").length - 1 ? "" : ", "}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={index}>
                <Link
                  to={`/showDrug/${route}/${word.trim().replace("/", "_")}`}
                >
                  {word}
                </Link>
                {index === field.split(";").length - 1 ? "" : ", "}
              </Fragment>
            );
          }
        } else {
          if (word.trim() === list) {
            return (
              <Fragment key={index}>
                <Link to={`/showDrug/${route}/${word.trim()}`}>
                  <mark>{word}</mark>
                </Link>
                {index === field.split(",").length - 1 ? "" : ", "}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={index}>
                <Link to={`/showDrug/${route}/${word.trim()}`}>{word}</Link>
                {index === field.split(";").length - 1 ? "" : ", "}
              </Fragment>
            );
          }
        }
      });
  };

  const { tab } = state;

  return loading || showDrug === undefined || showDrug === null ? (
    <Spinner />
  ) : (
    <Fragment>
      {state.bool && (
        <div className="description">
          <span className="btn text-primary float-right" onClick={descClick}>
            Close
          </span>
          <h1 className="display-3 text-center">Description</h1>
          <ul>
            <li>{showDrug.description}</li>
          </ul>
        </div>
      )}
      <div style={state.backgroundStyle}>
        <div className="mt-5 mx-5 mb-2">
          <div className="display-4 text-primary back-button" onClick={onClick}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <span className="text-center">
            <h1 className="display-3 mb-0">
              {/* {highlight(showDrug.name_of_medicine, search.name_of_medicine)} */}
              {showDrug.name_of_medicine.toUpperCase()}
            </h1>
            {showDrug.description !== "" && (
              <p className="mb-2">
                <button onClick={descClick} className="btn text-primary btn-lg">
                  Description
                </button>
              </p>
            )}
          </span>
        </div>
        <div className="container">
          <hr className="w-25" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <ul className="nav justify-content-center mb-3">
                <li className="nav-item">
                  <span
                    className={
                      "btn " + (tab === "1" ? "text-dark" : "text-primary")
                    }
                    onClick={(e) => onTab(e, "1")}
                  >
                    Information
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={
                      "btn " + (tab === "2" ? "text-dark" : "text-primary")
                    }
                    onClick={(e) => onTab(e, "2")}
                  >
                    Reference
                  </span>
                </li>
              </ul>
              {tab === "1" && (
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" style={{ width: "70%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Category</th>
                      <td>
                        {/* {showDrug.category_of_medicine && 
                                                highlight(showDrug.category_of_medicine, search.category_of_medicine)} */}
                        {showDrug.category_of_medicine}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Dispensing pack size</th>
                      <td>
                        {/* {showDrug.dispensing_pack_size &&
                                                highlight(showDrug.dispensing_pack_size, search.dispensing_pack_size)} */}
                        {showDrug.dispensing_pack_size}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Therapeutic indications</th>
                      <td>
                        {showDrug.therapeutic_indications &&
                          linkTagging(
                            showDrug.therapeutic_indications.replace(/;$/, ""),
                            search.therapeutic_indications,
                            "diseases"
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Dose and mode of administartion</th>
                      <td>
                        {/* {showDrug.dose_and_mode_of_administration &&
                                                highlight(showDrug.dose_and_mode_of_administration, search.dose_and_mode_of_administration)} */}
                        {showDrug.dose_and_mode_of_administration}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Precautions and contradictions</th>
                      <td>
                        {showDrug.precaution_or_contradiction === "NS" ? (
                          <p>None</p>
                        ) : (
                          showDrug.precaution_or_contradiction
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {tab === "2" && (
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" style={{ width: "70%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Reference text</th>
                      <td>
                        {/* {showDrug.reference_text &&
                                                highlight(showDrug.reference_text, search.reference_text)} */}
                        {showDrug.reference_text}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className="col-md-4 text-center order-first order-md-last">
              <img
                style={{ objectFit: "cover" }}
                id="show-image"
                className="img-thumbnail mx-auto d-block"
                src={
                  showDrug.drug_image === "-"
                    ? require("../../images/placeholder.jpg")
                    : showDrug.drug_image
                }
                alt="drug"
              />
              {showDrug.wiki_url !== "-" && (
                <a
                  className="d-block mt-2"
                  href={showDrug.wiki_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {"Wiki  "}
                  <i className="fas fa-external-link-alt"></i>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShowDrug;
