import React from 'react'

const Terms = () => {
    return (
        <div>
            <div className="container mt-4">
                <h1 className="display-4">
                    Terms of Use
                </h1>

                <p>
                    PLEASE READ THE TERMS OF USE CAREFULLY BEFORE, ACCESSING, USING, SUBSCRIBING OR REGISTERING ON OR ACCESSING ANY MATERIAL, INFORMATION THROUGH THE WEBSITE.

                </p>

                <p>
                    By accessing, using, subscribing or registering on or accessing any material, information through the website or by using the information given on the website you are providing an acceptance or agreement to all our policies, given in privacy policy; terms of use; refund and cancellation policy, disclaimer and shall be legally bound by the same.

                </p>

                <p>
                    IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THE WEBSITE.

                </p>

                <strong>
                    <em>
                        1. What is Ayurveda Informatics?
                    </em>
                </strong>

                <p>
                    The domain names <a href="www.ayurvedainformatics.com">www.ayurvedainformatics.com</a> and <a href="https://cosylab.iiitd.edu.in/">https://cosylab.iiitd.edu.in/</a>, an internet-based portal, owned and operated by Ayusla Private Limited, a company duly incorporated under the provisions of the Companies Act, 2013, having its CIN # U93090DL2017PTC322665 and with its registered office as-
                </p>

                <p>
                    <em>
                        H-5, 3rd Floor, Near Sanjay Park, Shakarpur, New Delhi, East Delhi, Delhi, 110092, India, (hereinafter, referred to as “Ayusla” or “We” or “Our” or “Us” or “Company”).
                    </em>
                </p>
                <p>
                    Your access or use of the Website, transaction on the Website and use of Services hosted or managed remotely through the Website, are governed by the following terms and conditions (referred to as “Terms of Use”), including the applicable policies which are incorporated herein by way of reference. These Terms of Use constitute a legal and binding contract between You, the User on one part and Ayusla on the other part.

                </p>
                <p>

                    By accessing, browsing or in any way transacting on the Website, or availing any Services, You signify your agreement to be bound by the Terms of Use. Further, by impliedly or expressly accepting these Terms of Use, you also accept and agree to be bound by Ayusla’s policies, including its Privacy Policy, and such other rules, guidelines, policies, terms and conditions as are applicable for the purposes of accessing, browsing or transacting on the Website, or availing any of the Services, and such rules, guidelines, policies, terms and conditions shall be deemed to be incorporated into, and considered as part and parcel of this Terms of Use. However, if you navigate away from the Website to a third-party site, you may be subject to alternative terms and conditions of use, as may be specified on such site. In such event, the terms and conditions of use applicable to that site will govern your use of that site.
                </p>

                <p>
                    The Company reserves the right to change or modify these Terms of Use or any policy or guideline of the Website including the Privacy Policy, at any time and in its sole discretion. Any changes or modifications will be effective immediately upon posting the revisions on the Website, and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Website will confirm your acceptance of such changes or modifications; therefore, you should frequently review these Terms of Use and applicable policies to understand the terms and conditions that apply to your use of the Website.
                </p>

                <p>
                    This Terms of Use is published in compliance of, and is governed by the provisions of Indian law (s), including but limited to:
                </p>

                <ul>
                    <li>
                        The Indian Contract Act, 1872 (“Contract Act”);
                    </li>
                    <li>
                        The (Indian) Information Technology Act, 2000 and the rules, regulations, guidelines and clarifications framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011, and the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011;
                    </li>
                </ul>

                <p>
                    Ayusla authorizes the User to view and access the content available on the Website solely for the purposes of availing the Services, such as visiting and communicating only as per the Terms of Use. The contents on the Website information, text, graphics, images, logos, button icons, software code, design, and the collection, arrangement and assembly of content, as well as in-house content provided by of Ayusla (“Ayusla Content”) (collectively, “Content”).

                </p>
                <p>
                    Ayurveda Informatics Content is the property of Ayusla and is protected under copyright, trademark and other laws. User shall not modify the Ayusla Content or reproduce, display, publicly perform, distribute, or otherwise use the Ayusla Content in any way for any public or commercial purpose or for personal gain.

                </p>

                <p>
                    Compliance with the Terms of Use would entitle the User to a personal, non-exclusive, non-transferable, limited privilege to access and transact on the Website.

                </p>
                <p>
                    These Terms of Use constitute an electronic record in terms of the Information Technology Act, 2000 and rules framed there under, as applicable and amended from time to time. This electronic record is generated by a computer system and does not require any physical or digital signatures.

                </p>

                <strong>
                    <em>
                        2.Eligibility
                    </em>
                </strong>


                <p>
                    For the purposes of accessing, interacting, exchanging information and transacting through the Website, the Users are required to obtain registration, in accordance with the procedure established by Ayusla in this regard. As part of the registration process, Ayusla may collect the following personal information from the Users:
                </p>
                <p>
                    Name;

                </p>
                <p>
                    User ID;

</p>
                <p>
                    Email address;

</p>
                <p>
                    Phone number;

</p>
                <p>
                    Date of Birth;

</p>
                <p>
                    Password chosen by the User;

</p>
                <p>
                    Professional status, experience and validating proof of the same

                </p>
                <p>
                    Valid financial and bank account information; and

                </p>
                <p>
                    Other details as the User may volunteer.

                </p>
                <p>
                    The registration on or use/ access of the Website is only available to natural persons, other than those who are ‘incompetent to contract’ under the Contract Act. That is, persons including minors, un-discharged insolvents etc. are not eligible to register on, or use/ access the Website.

                </p>
                <p>
By registering, accessing or using the Website, you accept the terms of this Terms of Use and represent and warrant to Ayusla that you are ‘competent to contract’ under the Indian Contract Act, 1872 and have the right, authority and capacity to use the Website and agree to and abide by its Terms of Use.
                    
                </p>
                <p>
A registered id can only be utilized by the person whose details have been provided and Ayusla does not permit multiple persons to share a single log in/ registration id. However, a registered user, being also a parent or legal guardian of a person ‘incompetent to contract’ such as minors or persons with unsound mind, would be permitted to access and use the Website for the purposes of viewing and interacting, on behalf of such persons.

                </p>
                <p>
You agree and acknowledge that You would (a) create only one account; (b) provide accurate, truthful, current and complete information when creating Your account and in all their dealings through the Website; (c) maintain and promptly update Your account information; (d) maintain the security of Your account by not sharing Your password with others and restricting access to Your account and Your computer; (e) promptly notify Ayusla if You discover or otherwise suspect any security breaches relating to the Website; and (f) take responsibility for all activities that occur under Your account and accept all risk of unauthorized access.

                </p>

                <strong>
                    <em>
3.Privacy Policy

                    </em>
                </strong>

<p>
Ayusla has duly formulated a policy on how the Company collects, uses and discloses personally identifiable information from its users (“Privacy Policy”). The Privacy Policy forms an integral part of these Terms of Use and the Users are requested to carefully read the same.

</p>


<strong>
    <em>

4.Services and Use of Website
    </em>
</strong>

<p>
    <em>
Platform to facilitate transaction of business

    </em>

</p>

<p>
Ayusla provides Services through the Website, which is a platform that facilitates viewing information regarding Plants, Herbs, Kitchen ingredients, Dravyaguna, Ayurvedic formulation and other Ayurveda Pharmacopoeia related data. You understand and agree that Ayusla and the Website merely provide information to its Users and persons browsing/visiting the Website.

</p>

<p>
The Ayusla Content is available on the Website, including without limitation, text, copy, audio, video, photographs, illustrations, graphics and other visuals, is for targeted at general public for informational purposes only and does not constitute professional medical advice, diagnosis, treatment or recommendations of any kind. Further, the details regarding the process of generating and reviewing the Ayusla Content is detailed in the editorial policy.

</p>

<p>
NO REGISTERED PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED BY USING THE CONTENT, WHETHER SUCH CONTENT IS PROVIDED BY OR THROUGH THE USE OF THE SERVICES OR THROUGH ANY OTHER COMMUNICATIONS FROM US.

</p>

<em>
Payment, Fees and Taxes
</em>

<p>
The Ayurveda Informatics website and mobile has two versions- The ‘Freemium’ with free registration has restricted access to the content and advanced features. The level of restriction is defined by Ayusla and is subject to change without prior notice / notification. The ‘Premium’ is the full version and provides the User complete access to all its utilities. The Premium pack is available for a Subscription fee as mentioned in the ‘Subscribe’ Page of the mobile/website or <a href="https://ayurvedainformatics.com">ayurvedainformatics.com</a> or <a href="https://cosylab.iiitd.edu.in/">https://cosylab.iiitd.edu.in/.</a>

</p>

<strong>
    <em>
5. Covenants
    </em>
</strong>

<p>
You undertake that You shall not do any act or post, display, upload, modify, publish, transmit, update or share any information that - belongs to another person and to which the User does not have any right; is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
</p>

<p>
infringes any patent, trademark, copyright or other intellectual proprietary rights of any third party;

</p>
<p>
violates any law for the time being in force;

</p>
<p>
impersonates another person;

</p>
<p>
contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;

</p>
<p>
is prohibited under any applicable law for the time being in force in territorial and extra territorial region of India;

</p>
<p>
threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.

</p>

<p>
The User is also prohibited from: violating or attempting to violate the integrity or security of the Website or any Ayurveda Informatics Content (s); transmitting any information (including job posts, messages and hyperlinks) on or through the Website that is disruptive or competitive to the provision of Services by Ayusla; intentionally submitting on the Website any incomplete, false or inaccurate information; making any unsolicited communications to other Users; using any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search the Website; attempting to decipher, decompile, disassemble or reverse engineer any part of the Website; copying or duplicating in any manner any of the Ayurveda Informatics Content (s) or other information available from the Website; framing or hotlinking or deep linking any such Content (s).
</p>

<p>
Ayusla, upon obtaining knowledge by itself or been brought to actual knowledge by an affected person in writing or through email signed with electronic signature about any such information as mentioned in Clause 5.ii above, shall be entitled to disable such information that is in contravention of Clause 5.ii. Ayusla shall be entitled to preserve such information and associated records for at least 90 (ninety) days for production to governmental authorities for investigation purposes.
</p>

<p>
In case of non-compliance with any applicable laws, rules or regulations, or the Agreement (including the Privacy Policy) by a User, Ayusla has the right to immediately terminate the access or usage rights of the User to the Services and to remove noncompliant information.
</p>

<strong>
    <em>
7. Liability
    </em>
</strong>

<p>
The Website may be linked to web pages and content of third parties, affiliates and business partners (collectively, “Third Party Content”). Ayusla does not control, endorse or adopt any Third-Party Content and can make no guarantee as to its accuracy or completeness. The User acknowledges and agrees that Ayusla shall not be responsible or liable in any manner for the content, accuracy, validity, reliability, quality of such Third-Party Content. Inclusion of any link on the Website does not imply that Ayusla endorses the linked site. User may use the links and these services at User's own risk.
</p>

<p>
Ayusla assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect User's equipment on account of User's access to, use of, or browsing the Website or the downloading of any material, data, text, images, video content, or audio content from the Website. If a User is dissatisfied with the Website, User's sole remedy is to discontinue using the Website.
</p>

<p>
To the maximum extent permitted by applicable law, Ayusla, its affiliates, independent contractors, service providers, consultants, licensors, agents, and representatives, and each of their respective directors, officers or employees (“Protected Entities”), shall not be liable for any direct, indirect, special, incidental, punitive, exemplary or consequential damages, or any other damages of any kind, arising from, or directly or indirectly related to, (i) the use of, or the inability to use, the Website or the content, materials and functions related thereto; (ii) User's provision of information via the Website; even if such protected entity has been advised of the possibility of such damages.
</p>

<p>
In no event shall the “Protected Entities” be liable for, or in connection with, (i) the provision of, or failure to provide, all or any service by Ayusla to any User; or (ii) any comments or feedback given by any of the Users in relation to the services provided by Ayusla; or (ii) any content posted, transmitted, exchanged or received by or on behalf of any User or other person on or through the Website.
</p>

<p>
In no event shall the total aggregate liability of the protected entities to a User for all damages, losses, and causes of action (whether in contract or tort, including, but not limited to negligence, strict liability, product liability or otherwise) arising from the Terms of Use or a User's use of the Website exceed an aggregate amount of Rs. 1000/- (Indian Rupees One Thousand Only). Ayusla accepts no liability for any errors or omissions, whether on behalf of the Users or third parties.
</p>

<strong>
    <em>
8.Indemnity
    </em>
</strong>

<p>
You agree to defend, indemnify and hold harmless Ayusla, its affiliates, independent contractors, service providers, consultants, licensors, agents, and representatives, and each of their respective directors, officers and employees, from and against any and all claims, losses, liability, damages, and/or costs (including, but not limited to, reasonable attorney fees and costs) arising from or related to (a) Your access to or use of Website; (b) Your violation of the Terms of Use; (c) Your violation of any rights of another person/ entity, including infringement of their intellectual property rights; or (d) Your conduct in connection with the Website.
</p>

<strong>
    <em>
9.Modification of Website
    </em>
</strong>

<p>
Ayusla reserves the right to modify or discontinue, temporarily or permanently, the Website or any features or portions thereof without prior notice. You agree that Ayusla will not be liable for any modification, suspension or discontinuance of the Website or any other part thereof.
</p>

<strong>
    <em>
10.Termination
    </em>
</strong>

<p>
The provisions of this Agreement shall continue to apply until terminated by either You or Ayusla as set for below: If You want to terminate this Agreement with Ayusla, you may do so by: not accessing the Website; or closing Your accounts for all of the Services that You use. Ayusla reserves the right to, at any time, and with or without notice, terminate this Agreement with You if: You breach any of applicable law (s), and the rules issued thereunder or the provisions of this Agreements or the terms of the Privacy Policy or any other terms, conditions, or policies that may be applicable to You from time to time (or have acted in a manner that clearly shows that You do not intend to, or are unable to, comply with the same); or Ayusla is unable to verify or authenticate any information provided to Ayusla by You; or Ayusla believes, in its sole discretion, that Your actions may cause legal liability for Ayusla (or any of its affiliates, independent contractors, service providers, consultants, licensors, agents, and representatives) or are contrary to the interests of the Website; or Ayusla is required to do so by law; or if You fail to provide (or after providing such consent, later revoke) the consents necessary or desirable for Ayusla to provide the Services to You; or The provision of the Services to you, or to the general public, is in Ayusla’s opinion, no longer commercially viable; or Ayusla has elected to discontinue, with or without reason, access to the Website or the Services (or any part thereof).
</p>

<p>
Ayusla may also terminate or suspend (temporarily or permanently) all or a portion of Your account or access to the Services, with or without reason. Except as may be set forth in any of the terms applicable to a particular Service, termination of Your account may include: (i) removal of access to all offerings within the Website or with respect to the Services; and (ii) barring You from further use or access of the Website or of any of the Services.
</p>

<p>
Once terminated or suspended (temporarily or permanently), You shall not continue to use the Website under the same account, a different account or re-register under a new account.
</p>

<p>
Upon termination of this Agreement, Ayusla shall have no obligation to maintain or provide any of Your data and may thereafter, unless legally prohibited, delete all Your data in its systems or otherwise in its possession or under its control, including but not limited to Your personal information, log-in ID and password, order details (including any prescriptions uploaded) and all related information, files and materials associated with or inside Your account (or any part thereof). The right to terminate/ suspend the account is in addition to, and without prejudice to, Ayusla’s right to initiate action against the User, in accordance with applicable law.
</p>

<strong>
    <p>
11. Force Majeure
    </p>
</strong>

<p>
The User accepts and acknowledges that Ayusla shall not be liable for any loss or damage caused to the User as a result of delay or default or deficiency or failure in the Services as a result of any natural disasters, fire, riots, civil disturbances, actions or decrees of governmental bodies, communication line failures (which are not caused due to the fault of the User), or any other delay or default or deficiency or failure which arises from causes beyond Ayusla. In the event of any Force Majeure Event arising, Ayusla or any third party on working on behalf of Ayusla, depending on whose performance has been impacted under the Terms of Use, shall immediately give notice to the User of the facts which constitute the Force Majeure Event.
</p>

<strong>
    <em>
12.Governing Law and Dispute Resolution
    </em>
</strong>

<p>
This Agreement and any contractual obligation between Ayusla and Users/user will be governed by the laws of India and any legal action or proceeding related to Your access to, or use of, the Website or these Terms of Use shall be subject to the exclusive jurisdiction of the courts at Delhi, India.
</p>

<strong>
    <em>
13.Survival

    </em>
</strong>

<p>
Even after termination, certain obligations mentioned under Covenants, Liability, Indemnity, Intellectual Property, Dispute Resolution will continue and survive termination.
</p>

<strong>
    <em>
14.Severability
    </em>
</strong>

<p>
If any provision of this Terms of Use is deemed invalid, unlawful, void or for any other reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any of the remaining provisions.
</p>

<strong>
    <em>
15. Contact Information
    </em>
</strong>

<p>
If any User has any grievance, comment, question or suggestion regarding any of our Services, please contact our customer service at cafeayusla@gmail.com.
</p>

<p>
If a User has any questions concerning Ayusla, the Website, this Agreement, or anything related to any of the foregoing, Ayusla can be reached at the following email address - cafeayusla@gmail.com or via the contact information available from the following hyperlink: <a href="https://ayurvedainformatics.com">https://ayurvedainformatics.com</a> or <a href="https://cosylab.iiitd.edu.in/">https://cosylab.iiitd.edu.in/</a>.

</p>


            </div>
        </div>
    )
}

export default Terms
