import Autosuggest from 'react-autosuggest';
import React, { Fragment } from 'react'
import axios from 'axios'

class AutocompleteAyurForm extends React.Component {
    constructor() {
        super();
        this.state = {
            value: '',
            list: [],
            suggestions: []
        };
    }

    componentDidMount() {
        const retrieve = async () => {
            const res = await axios.get(`/backend/search/autocomplete?col_name=${this.props.name}&value=`);
            this.setState({
                list: res.data.map(word => word[0])
            })
        }

        retrieve();
    }


    render() {


        const { value, suggestions } = this.state;


        const { name, setSearch, search, active } = this.props;

        const getSuggestions = value => {
            const inputValue = new RegExp(value.trim(), 'gi');
            const inputLength = inputValue.length - 4;

            const temp = inputLength === 0 ? [] : this.state.list.filter(lang => lang.match(inputValue));
            if (temp.length > 0) {
                active.setActive(true);
            }

            return temp;
        };

        const getSuggestionValue = suggestion => suggestion;

        const renderSuggestion = suggestion => (
            <div>
                {suggestion}
            </div>
        );

        const onChange = (event, { newValue }) => {
            this.setState({
                value: newValue
            });
            setSearch({
                ...search,
                [name]: newValue
            })
        };



        const onSuggestionsFetchRequested = ({ value }) => {
            this.setState({
                suggestions: getSuggestions(value)
            });
        };

        const onSuggestionsClearRequested = () => {
            this.setState({
                suggestions: []
            });
            setTimeout(() => {
                active.setActive(false);
            }, 0);
        };

        const inputProps = {
            name,
            placeholder: this.props.placeholder,
            value,
            onChange: onChange,
            className: "form-control-sm w-75"
        };



        // Finally, render it!
        return (
            <Fragment>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    highlightFirstSuggestion = {true}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />

            </Fragment>
        );
    }
}

export default AutocompleteAyurForm