import React, { useContext, Fragment, useState, useLayoutEffect } from "react";
import { useParams, Link } from "react-router-dom";
import SearchContext from "../../contexts/search/searchContext";
import Spinner from "../layout/Spinner";

const Show = (props) => {
  const { id } = useParams();
  const { show, loading, setShow, search } = useContext(SearchContext);
  const [state, setState] = useState({
    bool: false,
    backgroundStyle: {},
    tlc: false,
    ips: false,
    assay: false,
    tab: "1",
  });

  useLayoutEffect(() => {
    setShow(id);
    // eslint-disable-next-line
  }, []);

  const descClick = (e) => {
    if (!state.bool)
      setState({
        ...state,
        bool: true,
        backgroundStyle: {
          filter: "blur(3px)",
        },
      });
    else
      setState({
        ...state,
        bool: false,
        backgroundStyle: {},
      });
  };

  const onClick = (e) => {
    props.history.goBack();
  };

  const hideToggle = (e, name, set) => {
    setState({
      ...state,
      [name]: !set,
    });
  };

  const onTab = (e, s) => {
    setState({
      ...state,
      tab: s,
    });
  };

  const highlight = (text, name) => {
    if (name === "") return <span>{text}</span>;
    const num = text.toLowerCase().indexOf(name.toLowerCase());
    if (num >= 0) {
      return (
        <span>
          {text.slice(0, num)}
          <mark>{text.slice(num, num + name.length)}</mark>
          {text.slice(num + name.length, text.length)}
        </span>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  const multiHighlight = (text, list) => {
    const arr = text.split("; ").map((word) => word.trim());
    return arr.map((word, index) => {
      var flag = false;
      var x;
      var check;
      for (var i = 0; i < list.length; i++) {
        if (list[i] === "") continue;
        const num = word.toLowerCase().indexOf(list[i].toLowerCase());
        if (num >= 0) {
          flag = true;
          x = num;
          check = list[i];
          break;
        }
      }
      if (flag) {
        return (
          <span key={index}>
            {word.slice(0, x)}
            <mark>{word.slice(x, x + check.length)}</mark>
            {word.slice(x + check.length, word.length)}
            {index === arr.length - 1 ? "" : ", "}
          </span>
        );
      } else {
        return (
          <span key={index}>
            {word}
            {index === arr.length - 1 ? "" : ", "}
          </span>
        );
      }
    });
  };

  const chemConstHighlight = (text, list) => {
    return text.map((word, index) => {
      var flag = false;
      var x;
      var check;
      for (var i = 0; i < list.length; i++) {
        if (list[i] === "") continue;
        const num = word.toLowerCase().indexOf(list[i].toLowerCase());
        if (num >= 0) {
          flag = true;
          x = num;
          check = list[i];
          break;
        }
      }
      if (flag) {
        return (
          <span key={index}>
            {word.slice(0, x)}
            <mark>{word.slice(x, x + check.length)}</mark>
            {word.slice(x + check.length, word.length)}
            {index === text.length - 1 ? "" : ", "}
          </span>
        );
      } else {
        return (
          <span key={index}>
            {word}
            {index === text.length - 1 ? "" : ", "}
          </span>
        );
      }
    });
  };

  const linkTagging = (field, list, route) => {
    return field.split(";").map((word, index) => {
      var flag = false;
      if (Array.isArray(list)) {
        list.forEach((check) => {
          if (check === word.trim()) {
            flag = true;
          }
        });
        if (flag) {
          return (
            <Fragment key={index}>
              <Link to={`/show/${route}/${word.trim().replace("/", "_")}`}>
                <mark>{word}</mark>
              </Link>
              {index === field.split(";").length - 1 ? "" : ", "}
            </Fragment>
          );
        } else {
          return (
            <Fragment key={index}>
              <Link to={`/show/${route}/${word.trim().replace("/", "_")}`}>
                {word}
              </Link>
              {index === field.split(";").length - 1 ? "" : ", "}
            </Fragment>
          );
        }
      } else {
        if (word.trim() === list) {
          return (
            <Fragment key={index}>
              <Link to={`/show/${route}/${word.trim()}`}>
                <mark>{word}</mark>
              </Link>
              {index === field.split(",").length - 1 ? "" : ", "}
            </Fragment>
          );
        } else {
          return (
            <Fragment key={index}>
              <Link to={`/show/${route}/${word.trim()}`}>{word}</Link>
              {index === field.split(";").length - 1 ? "" : ", "}
            </Fragment>
          );
        }
      }
    });
  };

  const { tab } = state;

  return loading || show === undefined || show === null ? (
    <Spinner />
  ) : (
    <Fragment>
      {state.bool && (
        <div className="description">
          <span className=" btn text-primary float-right" onClick={descClick}>
            Close
          </span>
          <h1 className="display-3 text-center">Description</h1>
          <ul>
            {/* eslint-disable-next-line */}
            {show.description && show.description.split("b)").map((sentence, index) => {
              if (index === 0)
                return (
                  <li key="index">{sentence.slice(2, sentence.length)}</li>
                );
              else
                return (
                  <li key="index">{sentence.slice(0, sentence.length)}</li>
                );
            })}
          </ul>
        </div>
      )}

      <div style={state.backgroundStyle}>
        <div className="mt-5 mx-5 mb-2">
          <div className="display-4 text-primary back-button" onClick={onClick}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <span className="text-center">
            <h1 className="display-3 mb-0">
              {highlight(show.plant_name, search.plant_name)}
            </h1>
            <p className="botanical-name-show mb-0">
              <em>
                {show.botanical_name &&
                  highlight(
                    show.botanical_name
                      .split("/")
                      .map(
                        (word) =>
                          word[0].toUpperCase() +
                          word
                            .toLowerCase()
                            .slice(1, show.botanical_name.length)
                      )
                      .join(" / "),
                    search.botanical_name
                  )}
              </em>
            </p>
            {show.description && show.description !== "" && (
              <p className="mb-2">
                <button onClick={descClick} className="btn text-primary btn-lg">
                  Description
                </button>
              </p>
            )}
          </span>
        </div>

        <div className="container">
          <hr className="w-25" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <ul className="nav justify-content-center mb-3">
                <li className="nav-item">
                  {/* eslint-disable-next-line */}
                  <span
                    className={
                      "btn " + (tab === "1" ? "text-dark" : "text-primary")
                    }
                    onClick={(e) => onTab(e, "1")}
                  >
                    Information
                  </span>
                </li>
                <li className="nav-item">
                  {/* eslint-disable-next-line */}
                  <span
                    className={
                      "btn " + (tab === "2" ? "text-dark" : "text-primary")
                    }
                    onClick={(e) => onTab(e, "2")}
                  >
                    Dravyaguna
                  </span>
                </li>
                <li className="nav-item">
                  {/* eslint-disable-next-line */}
                  <span
                    className={
                      "btn " + (tab === "3" ? "text-dark" : "text-primary")
                    }
                    onClick={(e) => onTab(e, "3")}
                  >
                    Common Names
                  </span>
                </li>
                {show.table.length !== 1 && (
                  <li className="nav-item">
                    {/* eslint-disable-next-line */}
                    <span
                      className={
                        "btn " + (tab === "4" ? "text-dark" : "text-primary")
                      }
                      onClick={(e) => onTab(e, "4")}
                    >
                      Scientific Classification
                    </span>
                  </li>
                )}
                
                
                <li className="nav-item">
                  {/* eslint-disable-next-line */}
                  <span
                    className={
                      "btn " + (tab === "5" ? "text-dark" : "text-primary")
                    }
                    onClick={(e) => onTab(e, "5")}
                  >
                    References/Shlokas
                  </span>
                </li>
              </ul>

              {show.table.length !== 1 && tab === "4" && (
                <table className="table ">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" style={{ width: "70%" }}></th>
                    </tr>
                  </thead>
                  <tbody>

                    {show.table && show.table.map((list, index) => {
                      if (
                        index === show.table.length - 1 ||
                        index === show.table.length - 2
                      ) {
                        return (
                          <tr>
                            <th scope="row">{list.split(":")[0]}</th>
                            <td>
                              <em>{list.split(":")[1]}</em>
                            </td>
                          </tr>
                        );
                      }
                      return (
                        <tr>
                          <th scope="row">{list.split(":")[0]}</th>
                          <td>{list.split(":")[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}

              {tab === "1" && (
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" style={{ width: "70%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Part Used</th>
                      <td>
                        {show.part_used &&
                          highlight(show.part_used, search.part_used)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Culinary Category</th>
                      <td>
                        {show.culinary_category === null ? (
                          <p>None</p>
                        ) : (
                          show.culinary_category
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Distribution and Habitat</th>
                      <td>
                        {show.place && highlight(show.place, search.place)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Diseases</th>
                      <td>
                        {show.therapeutic_uses &&
                          linkTagging(
                            show.therapeutic_uses,
                            search.therapeutic_uses,
                            "diseases"
                          )}
                      </td>
                      {/* <td>{show.therapeutic_uses && multiHighlight(show.therapeutic_uses, search.therapeutic_uses)}</td> */}
                    </tr>
                    <tr>
                      <th scope="row">Dose</th>
                      <td>{show.dose}</td>
                    </tr>
                    <tr>
                      <th scope="row">Ayurvedic Formulations</th>
                      <td>
                        {show.important_formulations &&
                          linkTagging(
                            show.important_formulations,
                            search.important_formulations,
                            "ayurform"
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Chemical Constituents</th>
                      <td>
                        {show.chemical_constituents &&
                          chemConstHighlight(
                            show.chemical_constituents
                              .split("; ")
                              .map((word) => word.trim()),
                            search.chemical_constituents
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Identity, Purity and Strength</th>
                      <td>
                      {show.identity_purity_and_strength ? (
                        <>
                          {state.ips
                            ? show.identity_purity_and_strength
                            : show.identity_purity_and_strength.slice(0, 60)}
                          {show.identity_purity_and_strength.length > 60 && (
                            <span
                              className="text-primary btn m-0 p-0"
                              onClick={(e) => hideToggle(e, "ips", state.ips)}
                            >
                              {state.ips ? "Less" : "...More"}
                            </span>
                          )}
                        </>
                      ) : (
                        <p>None</p>
                      )}
                        
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Assay</th>
                      <td>
                      {show.assay ? (
                        <>
                        {state.assay ? show.assay : show.assay.slice(0, 60)}
                        {show.assay.length > 60 && (
                          <span
                            className="text-primary btn m-0 p-0"
                            onClick={(e) => hideToggle(e, "assay", state.assay)}
                          >
                            {state.assay ? "Less" : "...More"}
                          </span>
                        )}</>
                      ) : (
                        <p>None</p>
                      )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">TLC</th>
                      <td>
                      {show.tlc ? (
                        <>
                        {state.tlc ? show.tlc : show.tlc.slice(0, 60)}
                        {show.tlc.length > 60 && (
                          <span
                            className="text-primary btn m-0 p-0"
                            onClick={(e) => hideToggle(e, "tlc", state.tlc)}
                          >
                            {state.tlc ? "Less" : "...More"}
                          </span>
                        
                        )}</>
                      ) : (
                        <p>None</p>
                      )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Note</th>
                      <td>{show.note}</td>
                    </tr>
                    <tr>
                      <th scope="row">Effect on Tridosha</th>
                      <td>
                        {show.dosha_karma === null ? (
                          <p>None</p>
                        ) : (
                          linkTagging(
                            show.dosha_karma,
                            search.dosha_karma,
                            "dosha_karma"
                          )                          
                        )}
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              )}

              {tab === "2" && (
                <table className="table ">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" style={{ width: "70%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Karma</th>
                      <td>
                        {show.karma &&
                          linkTagging(show.karma, search.karma, "karma")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Rasa</th>
                      <td>
                        {show.rasa && multiHighlight(show.rasa, search.rasa)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Guna</th>
                      <td>
                        {show.guna && multiHighlight(show.guna, search.guna)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Virya</th>
                      <td>
                        {show.virya && highlight(show.virya, search.virya)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Vipaka</th>
                      <td>
                        {show.vipaka && highlight(show.vipaka, search.vipaka)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {tab === "3" && (
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "20%" }}></th>
                      <th scope="col" style={{ width: "30%" }}></th>
                      <th scope="col" style={{ width: "20%" }}></th>
                      <th scope="col" style={{ width: "30%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">English</th>
                      <td>{highlight(show.english, search.english)}</td>
                      <th scope="row">Hindi</th>
                      <td>
                        {show.hindi && highlight(show.hindi, search.hindi)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Sanskrit</th>
                      <td>
                        {show.sanskrit &&
                          highlight(show.sanskrit, search.sanskrit)}
                      </td>
                      <th scope="row">Bengali</th>
                      <td>
                        {show.bengali &&
                          highlight(show.sanskrit, search.sanskrit)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Assamese</th>
                      <td>
                        {show.assamese &&
                          highlight(show.assamese, search.assamese)}
                      </td>
                      <th scope="row">Gujrati</th>
                      <td>
                        {show.gujrati &&
                          highlight(show.gujrati, search.gujrati)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Kannada</th>
                      <td>
                        {show.kannada &&
                          highlight(show.kannada, search.kannada)}
                      </td>
                      <th scope="row">Kashmiri</th>
                      <td>
                        {show.kashmiri &&
                          highlight(show.kashmiri, search.kashmiri)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Malayalam</th>
                      <td>
                        {show.malayalam &&
                          highlight(show.malayalam, search.malayalam)}
                      </td>
                      <th scope="row">Marathi</th>
                      <td>
                        {show.marathi &&
                          highlight(show.marathi, search.marathi)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Oriya</th>
                      <td>
                        {show.oriya && highlight(show.oriya, search.oriya)}
                      </td>
                      <th scope="row">Punjabi</th>
                      <td>
                        {show.punjabi &&
                          highlight(show.punjabi, search.punjabi)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Tamil</th>
                      <td>
                        {show.tamil && highlight(show.tamil, search.tamil)}
                      </td>
                      <th scope="row">Telugu</th>
                      <td>
                        {show.telugu && highlight(show.telugu, search.telugu)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Urdu</th>
                      <td>{show.urdu && highlight(show.urdu, search.urdu)}</td>
                    </tr>
                  </tbody>
                </table>
              )}
              {tab === "5" && (
                <>
                <table className="table ">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" style={{ width: "100%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{show.references === null ? (
                    <p>None</p>
                  ) : (
                    show.references
                  )}</td>
                  </tr>
                  </tbody>
                  </table>
                </>
              )}
            </div>
            <div className="col-md-4 text-center order-first order-md-last">
              <img
                style={{ objectFit: "cover" }}
                id="show-image"
                className="img-thumbnail mx-auto d-block"
                src={
                  show.plant_image === "-"
                    ? require("../../images/placeholder.jpg")
                    : show.plant_image
                }
                alt="plant"
              />
              {show.wiki_url && show.wiki_url !== "-" && (
                <a
                  className="d-block mt-2"
                  href={show.wiki_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {"Wiki  "}
                  <i className="fas fa-external-link-alt"></i>
                </a>
              )}
            </div>
          </div>

          {/* <div className="container"></div> */}
        </div>

        {/* <div className="container mt-3">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8"></div>
            <div className="col-md-2"></div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default Show;
