import React, { useContext, useLayoutEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import recipeSearchContext from "../../../contexts/recipeSearch/recipeSearchContext";
import RecipeSearchItem from "./RecipeSearchItem";
import Spinner from "../../layout/Spinner";
import { result } from "./type";

const RecipesSearchResult = () => {
  const { recipes, loading, redirect, page, setPage } =
    useContext(recipeSearchContext);
console.log("Data1");
  const history = useHistory();

  const location = useLocation();

  const goBack = () => {
    history.push("/recipeSearch");
  };

  useLayoutEffect(() => {
    if (redirect) goBack();
    //  eslint-disable-next-line
  }, []);

  const onNext = (e) => {
    setPage(page + 1);
  };

  const onPrev = (e) => {
    setPage(page - 1);
  };

  if (sessionStorage.length === 0 || result.path_key !== location.key) {
    result.result_recipes = recipes;
    result.path_key = location.key;
    sessionStorage.clear();
  }

  console.log("Data2");
  console.log(recipes.length);
  if (recipes.length === 0 || recipes[0] === undefined) {
    return (
      <Fragment>
        <div>
          <h1 className="display-4 text-center mt-3">No Results Found</h1>
        </div>
      </Fragment>
    );
  } else {
    return loading ? (
      <Spinner />
    ) : (
      <div className="container mt-3">
        <div className="float-right m-4 pt-3">
          {page !== 1 && (
            <button onClick={onPrev} className="btn btn-light">
              {" "}
              Prev{" "}
            </button>
          )}
          <span className="pl-3 font-weight-bold">{`Page  ${page} `}</span>
          <span className="pr-3">
            {"/ " +
              (parseInt(result.result_recipes.length / 20) +
                (result.result_recipes.length % 20 === 0 ? 1 : 1)) +
              " "}
          </span>
          {page !==
            parseInt(result.result_recipes.length / 20) +
              (result.result_recipes.length % 20 === 0 ? 1 : 1) && (
            <button onClick={onNext} className="btn btn-light">
              {" "}
              Next{" "}
            </button>
          )}
        </div>
        <h1 className="display-3 ml-3">Results</h1>
        <table className="table table-hover text-center">
          <thead className="thead-dark">
            <tr>
              <th scope="col" style={{ width: "10%" }}>
                #
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Recipe name
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Region
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Total Time
              </th>
            </tr>
          </thead>
          <tbody>
            {result.result_recipes
              .slice((page - 1) * 20, page * 20)
              .map((recipe, index) => (
                <RecipeSearchItem
                  key={recipe.s_no}
                  index={index + 1 + (page - 1) * 20}
                  recipe={recipe}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export default RecipesSearchResult;
