import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import searchContext from "../../../contexts/search/searchContext";
import { Link } from "react-router-dom";

const SearchItem = ({ plant, index }) => {
  const { setLoading } = useContext(searchContext);

  const onClick = (e) => {
    setLoading(true);
  };

  const { botanical_name, english, plant_name, part_used, totalvariable, rank} = plant;
  return (
    <Fragment>
      <tr>
        <th scope="row">{index}</th>
        <td>
          <Link className="" to={`/show/${plant.s_no}`} onClick={onClick}>
            {(plant_name !== "" && plant_name !== null) &&
              plant_name[0].toUpperCase() +
                plant_name.slice(1, plant_name.length)}
          </Link>
        </td>
        <td>
          <i>
            {(botanical_name !== "" && botanical_name !== null) &&
              botanical_name
                .split("/")
                .map(
                  (word) =>
                    word[0].toUpperCase() +
                    word.toLowerCase().slice(1, botanical_name.length)
                )
                .join(" / ")}
          </i>
        </td>
        <td>
          {(english !== "" && english !== null) &&
            english
              .split("; ")
              .map(
                (word) =>
                  word[0].toUpperCase() +
                  word.toLowerCase().slice(1, english.length)
              )
              .join(" / ")}
        </td>
        <td>{part_used}</td>
        <td>
        <Link className="" to={`/show/${plant.s_no}`} onClick={onClick}>
          <button className="btn btn-secondary">View</button>
          </Link>
          </td>
          <td>
        {totalvariable > 0 ? ((rank / totalvariable) * 100).toFixed(2) + "%" : "0%"}
      </td>
      </tr>
    </Fragment>
  );
};

SearchItem.propTypes = {
  plant: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default SearchItem;
