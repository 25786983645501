import React, { Fragment, useLayoutEffect, useContext, useState } from 'react'
import profileContext from '../../contexts/profile/profileContext'
import Spinner from '../layout/Spinner'

const Profile = (props) => {

    const { username, email, dob, course, profession, gender, orgname, orgaddr, country_code, phone_number, getProfile, loading, updateProfile } = useContext(profileContext);

    useLayoutEffect(() => {

        getProfile();
        // eslint-disable-next-line
    }, [])

    const [state, setState] = useState({
        username: username,
        email: email,
        dob: dob,
        course: course,
        profession: profession,
        gender: gender,
        orgname: orgname,
        orgaddr: orgaddr,
        country_code: country_code,
        phone_number: phone_number
    })



    const onChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }


    const onSubmit = async e => {
        e.preventDefault();
        await updateProfile({
            username: state.username,
            email: state.email,
            dob: state.dob,
            course: state.course,
            profession: state.profession,
            gender: state.gender,
            orgname: state.orgname,
            orgaddr: state.orgaddr,
            country_code: state.country_code,
            phone_number: state.phone_number
        });
        props.history.push(`/Profile`)
    }

    return (loading ? <Spinner /> :
        <Fragment>
            <div className="mt-4 row mb-5">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="display-4 text-center mb-3">Edit Info</div>
                    <form >
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" value={state.username} autocomplete="off" onChange={onChange} name="username" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Email</label>
                            <div class="col-sm-10">
                                <input type="text" readonly class="form-control-plaintext" value={state.email} onChange={onChange} />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Contact</label>
                            <div class="col-sm-10">
                                +<input type="text" class="form-control" style={{ width: '10%', display: "inline", margin: "10px" }} value={state.country_code} autocomplete="off" onChange={onChange} name="country_code" />-
                                <input type="text" class="form-control" value={state.phone_number} style={{ width: '79%', display: "inline", margin: "10px" }} autocomplete="off" onChange={onChange} name="phone_number" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Date Of Birth</label>
                            <div class="col-sm-10">
                                <input type="date" class="form-control" value={state.dob} autocomplete="off" onChange={onChange} name="dob" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Course</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" value={state.course} autocomplete="off" onChange={onChange} name="course" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Profession</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" value={state.profession} autocomplete="off" onChange={onChange} name="profession" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Gender</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" value={state.gender} autocomplete="off" onChange={onChange} name="gender" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Organisation Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" value={state.orgname} autocomplete="off" onChange={onChange} name="orgname" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Organisation Address</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" value={state.orgaddr} autocomplete="off" onChange={onChange} name="orgaddr" />
                            </div>
                        </div>
                    </form>
                    <button className="btn btn-primary btn-block" onClick={onSubmit}>Submit</button>
                </div>
                <div className="col-md-3"></div>
            </div>
        </Fragment>)

}

export default Profile