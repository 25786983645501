import {
    GET_PROFILE
} from '../types'

const profileReducer = (state, action) => {
    switch (action.type) {
        case GET_PROFILE:
            return ({
                ...action.payload,
                dob: action.payload.dob.slice(0,15),
                loading: false
            })

        default: return ({ ...state })
    }
}

export default profileReducer