import React from "react";
import searchContext from "./searchContext";
import searchReducer from "./searchReducer";
import { useReducer } from "react";
import {
  GET_PLANT,
  SET_SHOW,
  SET_LOADING,
  CLEAR_PLANTS,
  SET_PAGE,
  SET_NAV,
  CLEAR_SEARCH,
  SET_KARMA,
  SET_AYURFORM,
  SET_DISEASES,
  CLEAR_SHOWPAGE,
  SET_DISEASEPAGE,
  SET_KARMAPAGE,
  SET_AYURFORMPAGE,
  SET_SIMILARPAGE,
} from "../types";

import axios from "axios";

const SearchState = (props) => {
  const initialSearch = {
    plant_name: "",
    part_used: "",
    botanical_name: "",
    place: "",
    english: "",
    sanskrit: "",
    assamese: "",
    bengali: "",
    gujrati: "",
    hindi: "",
    kannada: "",
    kashmiri: "",
    malayalam: "",
    marathi: "",
    oriya: "",
    punjabi: "",
    tamil: "",
    telugu: "",
    urdu: "",
    chemical_constituents: [""],
    rasa: [""],
    guna: [""],
    virya: "",
    vipaka: "",
    karma: [""],
    important_formulations: "",
    therapeutic_uses: [""],
    chemical_constituents_bool: false,
    rasa_bool: false,
    guna_bool: false,
    karma_bool: false,
    therapeutic_uses_bool: false,
  };

  const initialState = {
    plants: [],
    show: {},
    showKarma: null,
    showDisease: null,
    showAyurForm: null,
    page: 1,
    nav: "1",
    loading: true,
    redirect: true,
    search: initialSearch,
    karmaPage: 1,
    diseasePage: 1,
    ayurFormPage: 1,
    similarPage: 1,
  };

  const [state, dispatch] = useReducer(searchReducer, initialState);

  const getBasicPlant = async (search) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `/backend/basicsearch`,
      search,
      config
    );
    dispatch({ type: GET_PLANT, payload: res.data });
    return true;
  };

  const getPlant = async (search) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(`/backend/search`, search, config);
    dispatch({ type: GET_PLANT, payload: res.data });
    return true;
  };

  const setShow = async (s_no) => {
    const res = await axios.get(`/backend/plant/${s_no}`);
    dispatch({ type: SET_SHOW, payload: res.data[0] });
  };

  const setKarma = async (karma) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    var karmajson = JSON.parse(JSON.stringify(initialSearch));
    karmajson.karma[0] = karma;

    const res = await axios.post(`/backend/search`, karmajson, config);
    dispatch({ type: SET_KARMA, payload: res.data });
  };

  const setDiseases = async (disease) => {
    clearSearch();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    var json = JSON.parse(JSON.stringify(initialSearch));
    json.therapeutic_uses[0] = disease;
    // console.log(karmajson);

    const res = await axios.post(`/backend/search`, json, config);
    dispatch({ type: SET_DISEASES, payload: res.data });
  };

  const setAyurForm = async (ayurform) => {
    clearSearch();

    const res = await axios.get(`/backend/ayurvedic_form/${ayurform}`);

    dispatch({ type: SET_AYURFORM, payload: res.data });
  };

  const clearShowPage = (page) =>
    dispatch({ type: CLEAR_SHOWPAGE, payload: page });

  const setLoading = (loading) =>
    dispatch({ type: SET_LOADING, payload: loading });

  const clearPlants = (search) =>
    dispatch({ type: CLEAR_PLANTS, payload: search });

  const clearSearch = () =>
    dispatch({ type: CLEAR_SEARCH, payload: initialSearch });

  const setPage = (page) => dispatch({ type: SET_PAGE, payload: page });

  const setKarmaPage = (page) =>
    dispatch({ type: SET_KARMAPAGE, payload: page });

  const setDiseasePage = (page) =>
    dispatch({ type: SET_DISEASEPAGE, payload: page });

  const setAyurFormPage = (page) =>
    dispatch({ type: SET_AYURFORMPAGE, payload: page });

  const setSimilarPage = (page) =>
    dispatch({ type: SET_SIMILARPAGE, payload: page });

  const setNav = (nav) => dispatch({ type: SET_NAV, payload: nav });

  return (
    <searchContext.Provider
      value={{
        plants: state.plants,
        loading: state.loading,
        page: state.page,
        show: state.show,
        redirect: state.redirect,
        nav: state.nav,
        search: state.search,
        showKarma: state.showKarma,
        showDiseases: state.showDiseases,
        showAyurForm: state.showAyurForm,
        karmaPage: state.karmaPage,
        diseasePage: state.diseasePage,
        ayurFormPage: state.ayurFormPage,
        similarPage: state.similarPage,
        getBasicPlant,
        getPlant,
        setShow,
        setLoading,
        clearPlants,
        clearSearch,
        setPage,
        setDiseasePage,
        setKarmaPage,
        setAyurFormPage,
        setSimilarPage,
        setNav,
        setKarma,
        setDiseases,
        setAyurForm,
        clearShowPage,
      }}
    >
      {props.children}
    </searchContext.Provider>
  );
};

export default SearchState;