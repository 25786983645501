import React, { useContext, useLayoutEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import DrugSearchContext from "../../contexts/drugSearch/drugSearchContext";
import Spinner from "../layout/Spinner";
import DrugSearchItem from "../drugSearch/drugSearchResult/DrugSearchItem";

const ShowDrugDiseases = () => {
  const { diseases } = useParams();

  const {
    showDiseases,
    clearShowPage,
    setDiseases,
    clearSearch,
    diseasePage,
    setDiseasePage,
  } = useContext(DrugSearchContext);

  useLayoutEffect(() => {
    clearSearch();
    clearShowPage("showDrugDiseases");
    setDiseases(diseases);
    // eslint-disable-next-line
  }, []);

  const onNext = (e) => {
    setDiseasePage(diseasePage + 1);
  };

  const onPrev = (e) => {
    setDiseasePage(diseasePage - 1);
  };

  return showDiseases === null || showDiseases === undefined ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="text-center font-size-lg m-4">
        <h3 className="text-secondary m-0">Disease</h3>
        <h1 className="display-2 text-center">{diseases.replace("_", "/")}</h1>
      </div>
      <hr className="w-25" />
      <div className="container mb-5 text-center">
        <h4 className="font-weight-normal text-secondary text-center">
          Is Indicated Against
        </h4>
        <div className="float-right mb-4 mx-4">
          {diseasePage !== 1 && (
            <button onClick={onPrev} className="btn btn-light">
              {" "}
              Prev{" "}
            </button>
          )}
          <span className="pl-3 font-weight-bold">{`Page  ${diseasePage} `}</span>
          <span className="pr-3">
            {"/ " +
              (parseInt(showDiseases.length / 20) +
                (showDiseases.length % 20 === 0 ? 0 : 1)) +
              " "}
          </span>
          {diseasePage !==
            parseInt(showDiseases.length / 20) +
              (showDiseases.length % 20 === 0 ? 0 : 1) && (
            <button onClick={onNext} className="btn btn-light">
              {" "}
              Next{" "}
            </button>
          )}
        </div>
        <table className="table table-hover text-center">
          <thead className="thead-dark">
            <tr>
              <th scope="col" style={{ width: "10%" }}>
                #
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Drug Name
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Category
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Diseases
              </th>
            </tr>
          </thead>
          <tbody>
            {showDiseases
              .slice((diseasePage - 1) * 20, diseasePage * 20)
              .map((drug, index) => (
                <DrugSearchItem
                  key={drug.s_no}
                  index={index + 1 + (diseasePage - 1) * 20}
                  drug={drug}
                />
              ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default ShowDrugDiseases;
