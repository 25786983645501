import React from "react";
import ProfileReducer from "./profileReducer";
import ProfileContext from "./profileContext";
import { useReducer } from "react";
import axios from "axios";
import { GET_PROFILE } from "../types";

const ProfileState = (props) => {
  const initialProfile = {
    username: "",
    dob: "",
    email: "",
    country_code: "",
    phone_number: "",
    gender: "",
    profession: "",
    course: "",
    orgname: "",
    orgaddr: "",
    loading: true,
  };

  const [state, dispatch] = useReducer(ProfileReducer, initialProfile);

  const getProfile = async () => {
    const res = await axios.get("/backend/profileinfo");
    dispatch({ type: GET_PROFILE, payload: res.data[0] });
  };

  const updateProfile = async (profile) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put(
      "/backend/profupdate",
      profile,
      config
    );
    console.log(res);
    getProfile();
  };

  return (
    <ProfileContext.Provider
      value={{
        username: state.username,
        dob: state.dob,
        email: state.email,
        country_code: state.country_code,
        phone_number: state.phone_number,
        gender: state.gender,
        profession: state.profession,
        course: state.course,
        orgname: state.orgname,
        orgaddr: state.orgaddr,
        loading: state.loading,
        getProfile,
        updateProfile,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileState;
