// Get plants from the backend
export const GET_PLANT = 'GET_PLANT';

// Set the details of a single plant to show on the page
export const SET_SHOW = "SET_SHOW";

// Set loading state of the page
export const SET_LOADING = "SET_LOADING";

// Clear plant search results
export const CLEAR_PLANTS = "CLEAR_PLANTS";

// Set the current page number
export const SET_PAGE = "SET_PAGE";

// Set current navigation number
export const SET_NAV = "SET_NAV";

// Clear search input fields
export const CLEAR_SEARCH = "CLEAR_SEARCH";

// Get plants based on diseases
export const SET_DISEASES = "SET_DISEASES";

// Get plants based on karmas
export const SET_KARMA = "SET_KARMA";

// Get plants based on ayurvedic form information
export const SET_AYURFORM = "SET_AYURINFO";

// Clear plant/drug details from the page
export const CLEAR_SHOWPAGE = "CLEAR_PAGE";

// Set current page number for karma search results
export const SET_KARMAPAGE = "SET_KARMAPAGE";

// Set curreent page number for Ayurvedic form search results
export const SET_AYURFORMPAGE = "SET_AYURFORMPAGE";

// Set current page number for disease search results
export const SET_DISEASEPAGE = "SET_DISEASEPAGE";

// Set current page number for similar plant search results
export const SET_SIMILARPAGE = "SET_SIMILARPAGE"

// Clear any errors that occured
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

// Load user profile
export const USER_LOADED = 'USER_LOADED'

// Report an authentication error
export const AUTH_ERROR = 'AUTH_ERROR'

// Report a user profile authentication error
export const AUTH_PRO_ERROR = 'AUTH_PRO_ERROR'

// Log in the user
export const LOGIN_USER = 'LOGIN_USER'

// Get user profile
export const GET_PROFILE = 'GET_PROFILE'

/* -------------------------------------------------------------------------- */
// Get drugs from the backend
export const GET_DRUGS = 'GET_DRUGS'

// Set the details of a single drug to show on the page
export const SET_SHOWDRUGS = 'SET_SHOWDRUGS'

// Clear drugs search results
export const CLEAR_DRUGS = 'CLEAR_DRUGS'
/* -------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------- */
// Get drugs from the backend
export const GET_RECIPES = 'GET_RECIPES'

// Set the details of a single drug to show on the page
export const SET_SHOWRECIPES = 'SET_SHOWRECIPES'

// Clear drugs search results
export const CLEAR_RECIPES = 'CLEAR_RECIPES'
/* -------------------------------------------------------------------------- */