import React from "react";
import axios from "axios";
var fileDownload = require("js-file-download");

const Download = ({ link }) => {
  const onClick = async () => {
    console.log(link);
    const res = await axios.get("/backend/download/" + link);
    fileDownload(res.data, link + ".csv");
  };

  return (
    <div>
      <div>
        <span
          className="btn btn-primary m-3 "
          onClick={onClick}
        >{`${link}`}</span>
      </div>
    </div>
  );
};

export default Download;
