export const checkboxes = [
  {
    name: "Seed",
    key: "checkBox1",
    label: "Seed",
    v: "1"
  },
  {
    name: "Culinary Herb",
    key: "checkBox2",
    label: "Culinary Herb",
    v: "2"
  },
  {
    name: "Spice",
    key: "checkBox3",
    label: "Spice",
    v: "3"
  },
  {
    name: "Fruit",
    key: "checkBox4",
    label: "Fruit",
    v: "4"
  },
  {
    name: "Legume",
    key: "checkBox5",
    label: "Legume",
    v: "5"
  },
  {
    name: "Nut",
    key: "checkBox6",
    label: "Nut",
    v: "6"
  },
  {
    name: "Additive",
    key: "checkBox7",
    label: "Additive",
    v: "7"
  },
  {
    name: "Herb",
    key: "checkBox8",
    label: "Herb",
    v: "8"
  },
  {
    name: "Oil",
    key: "checkBox9",
    label: "Oil",
    v: "9"
  },
  {
    name: "Flower (Dried)",
    key: "checkBox10",
    label: "Flower (Dried)",
    v: "10"
  },
  {
    name: "Flower",
    key: "checkbox11",
    label: "Flower",
    v: "11"
  },
  {
    name: "Vegetable",
    key: "checkbox12",
    label: "Vegetable",
    v: "12"
  },
  {
    name: "Edible Leaf",
    key: "checkbox13",
    label: "Edible Leaf",
    v: "13"
  },
  {
    name: "Cereal",
    key: "checkbox14",
    label: "Cereal",
    v: "14"
  },
  {
    name: "Dairy",
    key: "checkbox15",
    label: "Dairy",
    v: "15"
  },
  {
    name: "Gourd",
    key: "checkbox16",
    label: "Gourd",
    v: "16"
  },
  {
    name: "Edible Leaf (Young and tender leaves)",
    key: "checkbox17",
    label: "Edible Leaf (Young and tender leaves)",
    v: "17"
  },
  {
    name: "Plant Derivative",
    key: "checkbox18",
    label: "Plant Derivative",
    v: "18"
  },
  {
    name: "Spice (Seed Powder)",
    key: "checkbox19",
    label: "Spice (Seed Powder)",
    v: "19"
  },
  {
    name: "Dry Fruit",
    key: "checkbox20",
    label: "Dry Fruit",
    v: "20"
  }
];