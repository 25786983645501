import React, { useContext, useEffect } from "react";
import SearchForm from "./searchForm/SearchForm";
import PlantSearch from "./searchForm/PlantSearch";
import Dravyaguna from "./searchForm/Dravyaguna";
import Diseases from "./searchForm/Diseases";
import DoshaKarma from "./searchForm/DoshaKarma";
import ChemicalConstituents from "./searchForm/ChemicalConstituents";
import AyurvedicFormulations from "./searchForm/AyurvedicFormulations";
import searchContext from "../../contexts/search/searchContext";

const Search = (props) => {
  const { nav, setNav, clearSearch } = useContext(searchContext);

  useEffect(() => {
    clearSearch();
    // eslint-disable-next-line
  }, []);

  const onClick = (e) => {
    setNav(e.target.name);
  };

  return (
    <div className="m-5 mt-5">
      <div className="container">
        <ul className="nav nav-tabs mb-4">
          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a
              className={nav === "1" ? "nav-link active" : "nav-link"}
              name="1"
              onClick={onClick}
              href="#"
            >
              Basic Search
            </a>
          </li>
          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a
              className={nav === "2" ? "nav-link active" : "nav-link"}
              name="2"
              onClick={onClick}
              href="#"
            >
              Dravyaguna <i className="fas fa-crown text-warning"></i>
            </a>
          </li>
          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a
              className={nav === "3" ? "nav-link active" : "nav-link"}
              name="3"
              onClick={onClick}
              href="#"
            >
              Chemical Constituents{" "}
              <i className="fas fa-crown text-warning"></i>
            </a>
          </li>
          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a
              className={nav === "4" ? "nav-link active" : "nav-link"}
              name="4"
              onClick={onClick}
              href="#"
            >
              Diseases <i className="fas fa-crown text-warning"></i>
            </a>
          </li>
          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a
              className={nav === "5" ? "nav-link active" : "nav-link"}
              name="5"
              onClick={onClick}
              href="#"
            >
              Effect on Tridosha <i className="fas fa-crown text-warning"></i>
            </a>
          </li>
          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a
              className={nav === "6" ? "nav-link active" : "nav-link"}
              name="6"
              onClick={onClick}
              href="#"
            >
              Ayurvedic Formulations{" "}
              <i className="fas fa-crown text-warning"></i>
            </a>
          </li>
          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a
              className={nav === "7" ? "nav-link active" : "nav-link"}
              name="7"
              onClick={onClick}
              href="#"
            >
              Advanced Search <i className="fas fa-crown text-warning"></i>
            </a>
          </li>
        </ul>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 search-form">
            {nav === "1" && <PlantSearch history={props.history} />}
            {nav === "2" && <Dravyaguna history={props.history} />}
            {nav === "3" && <ChemicalConstituents history={props.history} />}
            {nav === "4" && <Diseases history={props.history} />}
            {nav === "5" && <DoshaKarma history={props.history} />}
            {nav === "6" && <AyurvedicFormulations history={props.history} />}
            {nav === "7" && <SearchForm history={props.history} />}
          </div>
          <div className="col-md-3 display-md d-none d-md-block "></div>
        </div>
      </div>
    </div>
  );
};

export default Search;
