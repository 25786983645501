import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import profileContext from '../../contexts/profile/profileContext'
import authContext from '../../contexts/auth/authContext'

const Display = () => {

    const { username, email, dob, course, profession, gender, orgname, orgaddr, country_code, phone_number } = useContext(profileContext);

    const {user} = useContext(authContext)

    const months = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"]

    return <Fragment>
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-4 text-right mt-5">
                    <img alt="" src={require("../../images/profile.jpg")} className="pr-3" />

                </div>
                <div className="col-lg-8">
                    <div className="row pl-3">
                        <div className="display-4 mr-5">{username.split(" ").map((word) => word[0].toUpperCase() + word.slice(1,word.length)).join(" ")}</div>
                        <div className="my-auto updateProfile ml-5"><Link to="/UpdateProfile" className=" font-size-sm text-dark"><i class="far fa-edit"></i></Link></div>
                    </div>
                    <hr></hr>
                    <table class="table table-borderless">
                        <tbody>
                            {user && <><tr>
                                <td className="text-secondary">Subscription</td>
                                <td className="font-size-lg">{user[0].user_type}</td>

                            </tr>
                            {user[0].user_type === 'Pro' && <tr>
                                <td className="text-secondary">Expiry Date</td>
                                <td className="font-size-lg">{user[0].expiry_date.slice(8, 10) + " " + months[parseInt(user[0].expiry_date.slice(5, 7)) - 1] + " " + user[0].expiry_date.slice(0, 4)}</td>

                            </tr>}</>}
                            <tr>
                                <td className="text-secondary">Email</td>
                                <td className="font-size-lg">{email}</td>

                            </tr>
                            <tr>
                                <td className="text-secondary">Contact</td>
                                <td className="font-size-lg">{country_code?'+'+country_code+'-'+phone_number:phone_number}</td>

                            </tr>
                            <tr>
                                <td className="text-secondary">DoB</td>
                                <td className="font-size-lg">{dob}</td>

                            </tr>
                            <tr>
                                <td className="text-secondary">Course</td>
                                <td className="font-size-lg">{course}</td>

                            </tr>
                            <tr>
                                <td className="text-secondary">Profession</td>
                                <td className="font-size-lg">{profession}</td>

                            </tr>
                            <tr>
                                <td className="text-secondary">Gender</td>
                                <td className="font-size-lg">{gender}</td>

                            </tr>
                            <tr>
                                <td className="text-secondary">Organisation Name</td>
                                <td className="font-size-lg">{orgname}</td>

                            </tr>
                            <tr>
                                <td className="text-secondary">Organisation Address</td>
                                <td className="font-size-lg">{orgaddr}</td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </Fragment>

}

export default Display