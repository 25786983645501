import React, { useState, useContext } from "react";
import searchContext from "../../../contexts/search/searchContext";
import { Link } from "react-router-dom";
import AutocompleteAyurForm from "./inputs/AutocompleteAyurForm";

const AyurvedicFormulations = (props) => {
  const { getPlant, clearPlants } = useContext(searchContext);

  const initialState = {
    plant_name: "",
    part_used: "",
    botanical_name: "",
    place: "",
    english: "",
    sanskrit: "",
    assamese: "",
    bengali: "",
    gujrati: "",
    hindi: "",
    kannada: "",
    kashmiri: "",
    malayalam: "",
    marathi: "",
    oriya: "",
    punjabi: "",
    tamil: "",
    telugu: "",
    urdu: "",
    chemical_constituents: [""],
    rasa: [""],
    guna: [""],
    virya: "",
    vipaka: "",
    karma: [""],
    important_formulations: "",
    therapeutic_uses: [""],
    chemical_constituents_bool: false,
    rasa_bool: false,
    guna_bool: false,
    karma_bool: false,
    therapeutic_uses_bool: false,
    dosha_karma: [""],
    dosha_karma_bool: false,

  };

  const [search, setSearch] = useState(initialState);

  const [isActive, setActive] = useState(false);

  // const { plant_name, part_used, botanical_name, place } = search;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isActive) {
      const pathname =
        search.important_formulations !== ``
          ? `/show/ayurform/${search.important_formulations}`
          : "#";
      props.history.push(pathname);
    }
  };

  const context = {
    getPlant: getPlant,
    clearPlants: clearPlants,
    initialState: initialState,
  };

  return (
    <form onSubmit={onSubmit}>
      <h1 className="display-4">Ayurvedic Formulations</h1>
      <AutocompleteAyurForm
        placeholder={"Ayurvedic Formulations eg: Narayana Churna"}
        name={"important_formulations"}
        setSearch={setSearch}
        search={search}
        context={context}
        active={{ isActive: isActive, setActive: setActive }}
      />
      <span className="">
        <Link
          to={
            search.important_formulations !== ``
              ? `/show/ayurform/${search.important_formulations}`
              : "#"
          }
          className="btn btn-sm btn-secondary"
        >
          Go
        </Link>
      </span>
      <button type="submit" className="btn btn-primary btn-block w-75 mt-4">
        Submit
      </button>
    </form>
  );
};

export default AyurvedicFormulations;
