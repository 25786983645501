import Autosuggest from "react-autosuggest";
import React, { Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

class Multiselect extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      list: [],
      suggestions: [],
    };
  }

  componentDidMount() {
    const retrieve = async () => {
      const res = await axios.get(
        `/backend/recipeSearch/autocomplete?col_name=${this.props.col}&value=`
      );
      this.setState({
        list: res.data.map((word) => word[0]),
      });
    };
    retrieve();
  }

  render() {
    const { value, suggestions } = this.state;

    const { name, setSearch, search, hide, active } = this.props;
    const { getRecipe, clearRecipes, initialState } = this.props.context;

    const getSuggestions = (value) => {
      const inputValue = new RegExp(value.trim(), "gi");
      const inputLength = inputValue.length - 4;

      const temp =
        inputLength === 0
          ? []
          : this.state.list.filter((lang) => {
              if (lang !== undefined) return lang.match(inputValue);
              else return false;
            });

      if (temp.length > 0) {
        active.setActive(true);
      }

      return temp;
    };

    const getSuggestionValue = (suggestion) => suggestion;

    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

    const onChange = (event, { newValue }) => {
      this.setState({
        value: newValue,
      });
      let list = search[name].slice(0, search[name].length - 1);
      list.push(newValue);
      setSearch({
        ...search,
        [name]: list,
      });
    };

    const onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: getSuggestions(value),
      });
    };

    const onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: [],
      });
      setTimeout(() => {
        active.setActive(false);
      }, 0);
    };

    const onSuggestionSelected = (e, { suggestionValue }) => {
      let temp = search[name].slice(0, search[name].length - 1);
      temp.push(suggestionValue);
      temp.push("");
      setSearch({
        ...search,
        [name]: temp,
      });
      this.setState({
        value: "",
      });
    };

    const onClick = (e) => {
      clearRecipes(search);
      initialState[name] = search[name];
      var temp = name + "_bool";
      initialState[temp] = search[temp];
      getRecipe(initialState);
    };

    const listDelete = (e, index) => {
      let list = search[name];
      list.splice(index, 1);
      setSearch({
        ...search,
        [name]: list,
      });
    };

    const onToggle = (e) => {
      var temp = name + "_bool";
      setSearch({
        ...search,
        [temp]: e.target.checked,
      });
    };
    let inputProps = {
      name,
      placeholder: this.props.placeholder,
      value,
      onChange: onChange,
      className: "form-control-sm multiselect",
    };
    if(!hide){
      inputProps = {
        name,
        placeholder: this.props.placeholder,
        value,
        onChange: onChange,
        className: "form-control-sm w-75 multiselect",
      };
    }

    // Finally, render it!
    return (
      <Fragment>
        <ul
          className={
            search[name]
              ? "mb-1 multiselect-list p-0"
              : "m-0 multiselect-list p-0"
          }
        >
          {search[name].slice(0, search[name].length - 1).map((val, index) => (
            <li key={index}>
              {val}{" "}
              <span onClick={(e) => listDelete(e, index)}>
                <i className="fas fa-times"></i>
              </span>
            </li>
          ))}
        </ul>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          highlightFirstSuggestion={true}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
        />

        <span className="">
          <Link
            to={`/recipeSearch/result`}
            onClick={onClick}
            tabIndex="-1"
            className="btn btn-sm btn-secondary"
          >
            Go
          </Link>
        </span>
        {hide && (
          <input
            type="checkbox"
            className="ml-4"
            onChange={onToggle}
            data-tip
            data-for="check"
          ></input>
        )}

        <ReactTooltip id="check" place="right" effect="float" className="w-25">
          Tick this box so that all the values in this field are compulsory
        </ReactTooltip>
      </Fragment>
    );
  }
}

export default Multiselect;