import React from 'react'

const Footer = () => {
    return (
        <footer className="primary-footer text-center text-white bg-dark p-1 pt-4">
            <div className="content">
                {/*
                <p>
                    <img alt="" src={require("../../images/iiitd.png")} className="pr-3" />
                    <img alt="" src={require("../../images/ayusla_final.png")} className="pl-3" />
                </p>
                */}
                <p>Copyright &copy; 2020  All rights reserved.<br /><br />
                    <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/3.0/">
                        <img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-sa/3.0/88x31.png" />
                    </a>
                    <br />
                    This work is licensed under a{' '}
                    <a className="footer-a" rel="license" href="http://creativecommons.org/licenses/by-nc-sa/3.0/">
                        Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License
                    </a>.
				</p>
                {/*
                <p>
                    <a className="footer-a pr-2" href="https://iiitd.ac.in/" target="_blank" rel="noopener noreferrer">
                        <strong>IIIT-Delhi</strong>
                    </a>
                    <a className="footer-a pr-4" href="http://faculty.iiitd.ac.in/~bagler/" target="_blank" rel="noopener noreferrer">
                        Dr. Ganesh Bagler
                    </a>
                    <span>|</span>
                    <a className="footer-a pl-4 pr-2" href="http://ayuslacafe.com/" target="_blank" rel="noopener noreferrer">
                        <strong>Ayusla</strong>
                    </a>
                    <a className="footer-a" href="https://www.linkedin.com/in/rishi-agarwal-010a50103/" target="_blank" rel="noopener noreferrer">
                        Rishi Agarwal
                    </a>
                </p>
                <p className="social-icons mb-0">
                    <span className="pr-5 mr-5">
                        <a className="footer-a" target="_blank" href="https://www.facebook.com/ganesh.bagler" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                        <a className="footer-a" target="_blank" href="https://twitter.com/gansbags" rel="noopener noreferrer">   <i className="fab fa-twitter-square"></i></a>
                        <a className="footer-a" target="_blank" href="https://www.linkedin.com/in/ganeshbagler" rel="noopener noreferrer">   <i className="fab fa-linkedin"></i></a>
                    </span>
                    <span>
                        <a className="footer-a" target="_blank" href="https://www.facebook.com/rishi.agarwal.94214" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                        <a className="footer-a" target="_blank" href="https://www.linkedin.com/in/rishi-agarwal-010a50103/" rel="noopener noreferrer">   <i className="fab fa-linkedin"></i></a>
                    </span>

                </p>
                */}
                <p>
                    <a className="footer-a px-2" target="_blank" href="/disclaimer" rel="noopener noreferrer">Disclaimer</a>| 
                    <a className="footer-a px-2" target="_blank" href="/policy" rel="noopener noreferrer">Policy</a>|
                    <a className="footer-a px-2" target="_blank" href="/terms" rel="noopener noreferrer">Terms</a>
                </p>

            </div>
        </footer>
    )
}

export default Footer