import React from 'react'

const Disclaimer = () => {
    return (
        <div>
            <div className="container mt-4 mb-4">
                <h1 className="display-3">
                    Disclaimer
                </h1>
                <div className="row my-4 ">
                    <div className="col-md-8">
                        The information contained on <a href="https://cosylab.iiitd.edu.in/">https://cosylab.iiitd.edu.in/</a> or sub-domains is
                        provided for informational purposes only and is not meant to substitute for the advice
                        provided by your doctor or other healthcare professional or any research scholar experts
                        related to this field. Information, Knowledge database and statements regarding medicinal
                        plants, herbs, kitchen ingredients and ayurvedic formulations listed on Ayurveda Informatics
                        have been taken/based on data published by Ministry of AYUSH, a government authority and
                        from other authentic sources and are not intended to diagnose, treat, cure, or prevent any
                        disease. This is solely for informative purpose.
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-8">
                        Some versions or content on Ayurveda Informatics (or any other sub-domains) are primarily
                        intended for healthcare practitioners. A user is expected to have a basic understanding of
                        body functions, diseases and culinary arts to make use of these. A fundamental knowledge on
                        diagnosis and treatment is also beneficial.
                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-8">
                        Ayusla (<a href="https://cosylab.iiitd.edu.in/">https://cosylab.iiitd.edu.in/</a> or sub-domains) do not support or promote self-
                        medication or quackery. Always consult your licensed healthcare practitioner/medical
                        practitioner before using any of the information given in this website or application. Do not
                        discontinue or introduce any medicine or advice without the knowledge /advice of your
                        healthcare consultant.
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-8">
                        In no event will we be liable for any loss or damage including without limitation, indirect or
                        consequential loss or damage, or any loss or damage whatsoever arising from loss of data or
                        profits are arising out of, or in connection with, the use of this website.
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-8">
                        On Ayurveda Informatics (<a href="https://cosylab.iiitd.edu.in/">https://cosylab.iiitd.edu.in/</a> or sub-domains), you may
                        also find the links to other websites that are not under the control of Ayusla Private Limited.
                        We have no control over the nature, content and availability of those sites. The inclusion of
                        any links does not necessarily imply a recommendation or endorse the views expressed by
                        them.
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        Every effort is made to keep the website up and running smoothly. However, Ayusla Private
                        Limited takes no responsibility for, and will not be liable for, the website being temporarily
                        unavailable due to technical issues which are beyond our control.
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-8">
                        Ayusla Private Limited makes no representation as to the suitability of the information
                        contained on this website, for any purpose. It is provided “as is” without express or implied
                        warranty. The content of Ayurveda Informatics Website is provided for information purposes
                        only. The information is provided on the basis that readers will be responsible for making
                        their own assessment of the content presented.
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-8">
                        Ayusla Private Limited disclaims all warranties with regard to this information, in no event
                        shall Ayusla Private Limited be liable for indirect or consequential damages or any damages
                        whatsoever resulting from loss of use, data or notes, medicine compositions, whether in an
                        action of contract, negligence or other tortious action, arising out of or in connection with the
                        use or performance of this information.
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-8">
                        Ayusla Private Limited does not assume any legal liability or responsibility for the accuracy,
                        completeness, or usefulness of any information contained in this website. No decisions
                        regarding medical care or treatment or kitchen recipes should be made on the basis of
                        information contained in this website alone. The responsibility for proper medical treatment
                        rests with the individual and him/her treating physician.
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Disclaimer
