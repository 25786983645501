import React, { useState, useContext } from "react";
import recipeSearchContext from "../../../contexts/recipeSearch/recipeSearchContext"
import Autocomplete from "./inputs/Autocomplete"
import Dropdown from "./inputs/Dropdown"
import Multiselect from "./inputs/Multiselect"

const RecipeSearchForm = (props) => {
    console.log("abc5");
    const { getRecipe, clearRecipes } = useContext(recipeSearchContext);
    console.log("abc6");

    const initialState = {
        name_of_recipe: "",
        cuisine: [""],
        ingredients_used: [""],
        ingredients_not_used: [""]
    }

    const [search, setSearch] = useState(initialState);

    const [isActive, setActive] = useState(false);

    console.log("abc7");
    const onSubmit = (e) => {
        e.preventDefault();
        if (!isActive) {
            clearRecipes(search);
            getRecipe(search);
            props.history.push(`/recipeSearch/result`)
        }
    }

    console.log("abc8");
    const context = {
        getRecipe: getRecipe,
        clearRecipes: clearRecipes,
        initialState: initialState
    }

    return (
        <form onSubmit={onSubmit}>
            <h1 className="display-4">Recipe</h1>
            <div className="form-group">
                <label htmlFor="name_of_recipe" className="d-block">
                    Recipe Name
                </label>
                <Autocomplete
                    placeholder={"Recipe Name eg: Masala Karela Recipe"}
                    name={"name_of_recipe"}
                    setSearch={setSearch}
                    search={search}
                    context={context}
                    active={{ isActive: isActive, setActive: setActive }}
                />
            </div>
            <div className="form-group">
                <label htmlFor="therapeutic_indications" className="d-block">
                    Ingredients Used (Select Multiple Values)
                </label>
                <Multiselect
                    placeholder={"Ingredients Used eg: Coriander powder"}
                    name={"ingredients_used"}
                    col={"ingredients"}
                    setSearch={setSearch}
                    search={search}
                    context={context}
                    active={{ isActive: isActive, setActive: setActive }}
                    hide={false}
                />
            </div>
            <div className="form-group">
                <label htmlFor="therapeutic_indications" className="d-block">
                    Ingredients Not Used (Select Multiple Values)
                </label>
                <Multiselect
                    placeholder={"Ingredients Used eg: Coriander powder"}
                    name={"ingredients_not_used"}
                    col={"ingredients"}
                    setSearch={setSearch}
                    search={search}
                    context={context}
                    active={{ isActive: isActive, setActive: setActive }}
                    hide={false}
                />
            </div>
            
            <div className="form-group">
                <label htmlFor="cuisine" className="d-block">
                    Region (Select Multiple Values)
                </label>
                <Multiselect
                    placeholder={"Region eg: South Indian Recipes"}
                    name={"cuisine"}
                    col={"cuisine"}
                    setSearch={setSearch}
                    search={search}
                    context={context}
                    active={{ isActive: isActive, setActive: setActive }}
                    hide={false}
                />
            </div>
            <br />
            <button type="submit" className="btn btn-primary btn-block w-75 mt-4">
                Submit
            </button>
        </form>
    )
}

export default RecipeSearchForm