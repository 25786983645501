import React, { useState, useContext } from "react";
import drugSearchContext from "../../../contexts/drugSearch/drugSearchContext"
import Autocomplete from "./inputs/Autocomplete"
import Dropdown from "./inputs/Dropdown"
import Multiselect from "./inputs/Multiselect"

const DrugSearchForm = (props) => {
    const { getDrug, clearDrugs } = useContext(drugSearchContext);

    const initialState = {
        name_of_medicine: "",
        category_of_medicine: "",
        therapeutic_indications: [""],
        therapeutic_indications_bool: false
    }

    const [search, setSearch] = useState(initialState);

    const [isActive, setActive] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        if (!isActive) {
            clearDrugs(search);
            getDrug(search);
            props.history.push(`/drugSearch/result`)
        }
    }

    const context = {
        getDrug: getDrug,
        clearDrugs: clearDrugs,
        initialState: initialState
    }

    return (
        <form onSubmit={onSubmit}>
            <h1 className="display-4">Drug</h1>
            <div className="form-group">
                <label htmlFor="name_of_medicine" className="d-block">
                    Drug Name
                </label>
                <Autocomplete
                    placeholder={"Drug Name eg: Abhayarishta"}
                    name={"name_of_medicine"}
                    setSearch={setSearch}
                    search={search}
                    context={context}
                    active={{ isActive: isActive, setActive: setActive }}
                />
            </div>
            <div className="form-group">
                <label htmlFor="category_of_medicine" className="d-block">
                    Category
                </label>
                <Dropdown
                    placeholder={"category_of_medicine eg: Asava Arista"}
                    name={"category_of_medicine"}
                    setSearch={setSearch}
                    search={search}
                    context={context}
                    active={{ isActive: isActive, setActive: setActive }}
                />
            </div>
            <div className="form-group">
                <label htmlFor="therapeutic_indications" className="d-block">
                    Diseases (Select Multiple Values)
                </label>
                <Multiselect
                    placeholder={"Diseases eg: Agnimandya"}
                    name={"therapeutic_indications"}
                    setSearch={setSearch}
                    search={search}
                    context={context}
                    active={{ isActive: isActive, setActive: setActive }}
                    hide={true}
                />
            </div>
            <br />
            <button type="submit" className="btn btn-primary btn-block w-75 mt-4">
                Submit
            </button>
        </form>
    )
}

export default DrugSearchForm