import React, { Fragment } from 'react'
import ContactMap from '../layout/ContactMap'

const Contact = () => {
    return (<Fragment>
        <div className="container bg-light rounded mt-4 mb-5 p-5">
            <div className="row ">
                <div className="col-md-6">
                    <h1 className="pb-3 display-4">Contact Us</h1>
                    <div className="row">
                        <div className="col-md-6">
                            <a className="m-0 font-weight-bold font-size-lg" href="http://faculty.iiitd.ac.in/~bagler/" style ={{color: "black"}}>Dr. Ganesh Bagler</a><br/>
                            <a target="_blank" rel="noopener noreferrer" href="http://ccb.iiitd.ac.in/">Center for Computational Biology</a>
                            <p className="m-0 font-weight-normal">Indraprastha Institute of Information Technology Delhi</p>
                            <span className="text-dark ">
                                <p className="m-0"><strong>Address:</strong></p>
                                <p className="m-0">B-205, </p>
                                <p className="m-0">Academic Block,</p>
                                <p className="m-0">Okhla Phase III, </p>
                                <p className="m-0">Near Govindpuri Metro Station,</p>
                                <p className="m-0">New Delhi, India 110020.</p>
                            </span>
                            <a className="float-right font-weight-bold" href="mailto: bagler+AyurInfo@iiitd.ac.in">bagler+AyurInfo@iiitd.ac.in</a>
                            <p className="float-right font-weight-bold"> +91-11-26907-443 (Work)</p>
                        </div>
                        <div className="col-md-6">
                            <a className="m-0 font-weight-bold font-size-lg" href="https://www.linkedin.com/in/rishi-agarwal-010a50103/" style ={{color: "black"}}>Rishi Agarwal</a><br/>
                            <a target="_blank" rel="noopener noreferrer" href="http://ayuslacafe.com/">Ayusla (R&D)</a>
                            <p className="m-0"> IIITD Innovation and Incubation Centre</p>
                            <span className="text-dark">
                                <p className="m-0"><strong>Address:</strong></p>
                                <p className="m-0">Okhla Industrial Estate Ph 3 Rd,</p>
                                <p className="m-0"> Harkesh Nagar,</p>
                                <p className="m-0">Okhla Industrial Area, </p>
                                <p className="m-0">Near Govindpuri Metro Station,</p>
                                <p className="m-0">New Delhi, India 110020.</p>
                            </span>
                            <a className="float-right font-weight-bold" href="mailto: ayurinfo@ayusla.com">ayurinfo@ayusla.com</a>
                            <p className="float-right font-weight-bold"> +91-7662080126</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <ContactMap />
                </div>
            </div>
        </div>
    </Fragment>)
}

export default Contact