import React from 'react'
import { GoogleMap, withScriptjs, withGoogleMap } from 'react-google-maps'

const Map = () => {
    return (
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{ lat: 28.545760, lng:  77.273258 }}
        />
    )
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

const ContactMap = () => {
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <WrappedMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAeGMhSx59QZHZH3yCmrvS2Kk2cLD7o4co`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
    )
}

export default ContactMap