import React, { Fragment, useEffect } from 'react'

const About = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_GnfurpF7Dp1YXt");

        document.getElementById("form").appendChild(script);

        return () => {
            document.getElementById("form").removeChild(script);
        }
    }, [])

    return <Fragment>
        <section className="mt-4">
            <div className="container">
                <div className="display-4 ml-4" style={{ fontSize: "250%" }}>About</div>
                {/* <hr className="m-0 w-25 mt-2 ml-3"></hr> */}
                <div className="display-4 pt-0">Ayurveda Informatics</div>
                <div className="row mt-4">
                    <div className="col-md-3">
                        <div className="text-right pt-4" style={{ fontSize: "1.2rem" }}>
                            Welcome to Ayurveda Informatics Platform- An integrated digital tool for Ayurveda
                            Students, Practitioners, Researchers, Naturopathist, Chefs, Micro Bloggers and Ayurveda
                            enthusiasts.
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className=" w-75 ml-4 pt-3 px-5 pb-1 col-md-9" style={{ borderLeft: "solid #6495ED 4px" }}>
                            <div className="mb-3">
                                You can get the Ayurveda Pharmacopoeia and Dravyaguna associated information of Ayurvedic Ingredients which includes herbs, plants and food articles with multi-search capabilities to give you filtered results in rank wise order.
                            </div>
                            <div>
                                <strong>
                                    Ayurveda Informatics, subscribe to get access:
                                </strong>
                                <div className="mb-3">
                                    This is the first beta version of Ayurveda
                                    Informatics, finally enabling the end-users to get access to ingredients from Ayurveda knowledgebase based on Dravyaguna features (Rasa, Guna, Virya, Vipaka, Karma), chemical constituents, diseases and other advanced features.
                                </div>
                                <div className="mb-3">
                                    Helpful for developing the Ayurvedic formulations, Ayurveda-driven food recipes, work on research papers, thesis, blogs and microblogs.
                                </div>
                                <div className="mb-3">
                                    <em>
                                        This digital tool can be your companion in your BAMS and MD course in Ayurveda.
                                    </em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <section className="mt-4 text-right mb-5">
            <div className="container">
                {/* <div className="display-4 ml-4" style={{ fontSize: "250%" }}>About</div> */}
                {/* <hr className="m-0 w-25 mt-2 ml-3"></hr> */}
                <div className="display-4 pt-0" style={{ marginRight: "70%" }}>Supported By</div>
                
                <div className="row mt-4">
               
                        <div className="mb-3" style={{ fontSize: "1.1rem" , marginLeft:"3%"}}>
                        We acknowledge the support of following incubation centers, research and funding partners for our startup.
                        </div>
                    <figure style={{marginLeft: "20%"}}>
                    <img alt="" src={require("../../images/ayurveda-resources-1.png")} height = "80"/>
                    <figcaption className="text-center pt-1" style={{ fontSize: "0.9rem" }}><strong>CoSy Lab</strong></figcaption>
                    </figure>
                    <img alt="" src={require("../../images/nstedb.jpg")} height = "100" width = "120" style={{marginLeft: "10%"}}/>
                    <img alt="" src={require("../../images/ayurveda-resources-3.png")} height="80" style={{marginLeft: "10%"}}/>
                </div>     
               
               
            </div>
        </section>
        <section className="mt-4 text-right mb-5">
            <div className="container">
                {/* <div className="display-4 ml-4" style={{ fontSize: "250%" }}>About</div> */}
                {/* <hr className="m-0 w-25 mt-2 ml-3"></hr> */}
                <div className="display-4 pt-0" style={{ marginRight: "13%" }}>Support the Project</div>
                <div className="row mt-4">

                    <div className="col-md-7 order-2 order-md-1">
                        <div className="pt-3 px-5 pb-4 " style={{ borderRight: "solid #6495ED 4px" }}>
                            <div className="mb-3">
                                Your donation is our motivation and fuel to this ongoing research
                                work. Donate for this vision of reviving Ayurved through computational tools to become true.
                                Even the small donation counts, feel free to donate.

                        </div>
                        </div>
                    </div>
                    <div className="col-md-5 text-left pt-4 order-1 order-md-2">
                        <form id="form"></form>
                    </div>
                </div>

            </div>
        </section>
    </Fragment>
}

export default About