import React, { useState, useContext } from "react";
import searchContext from "../../../contexts/search/searchContext";
import Multiselect from "./inputs/Multiselect";

const Diseases = (props) => {
  const { getPlant, clearPlants } = useContext(searchContext);

  const initialState = {
    plant_name: "",
    part_used: "",
    botanical_name: "",
    place: "",
    english: "",
    sanskrit: "",
    assamese: "",
    bengali: "",
    gujrati: "",
    hindi: "",
    kannada: "",
    kashmiri: "",
    malayalam: "",
    marathi: "",
    oriya: "",
    punjabi: "",
    tamil: "",
    telugu: "",
    urdu: "",
    chemical_constituents: [""],
    rasa: [""],
    guna: [""],
    virya: "",
    vipaka: "",
    karma: [""],
    important_formulations: "",
    therapeutic_uses: [""],
    chemical_constituents_bool: false,
    rasa_bool: false,
    guna_bool: false,
    karma_bool: false,
    therapeutic_uses_bool: false,
    dosha_karma: [""],
    dosha_karma_bool: false,
  };

  const [search, setSearch] = useState(initialState);

  const [isActive, setActive] = useState(false);

  // const { plant_name, part_used, botanical_name, place } = search;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isActive) {
      clearPlants(search);
      getPlant(search);
      props.history.push(`/search/result`);
    }
  };

  const context = {
    getPlant: getPlant,
    clearPlants: clearPlants,
    initialState: initialState,
  };

  return (
    <form onSubmit={onSubmit}>
      <h1 className="display-4">Diseases</h1>
      <small id="emailHelp" className="d-inline mb-2 form-text text-muted">
        Select Multiple Values
      </small>
      <Multiselect
        placeholder={"Diseases eg: Prameha"}
        name={"therapeutic_uses"}
        setSearch={setSearch}
        search={search}
        context={context}
        active={{ isActive: isActive, setActive: setActive }}
        hide={true}
      />
      <button type="submit" className="btn btn-primary btn-block w-75 mt-4">
        Submit
      </button>
    </form>
  );
};

export default Diseases;
