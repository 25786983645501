import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import recipeSearchContext from "../../../contexts/recipeSearch/recipeSearchContext";
import { Link } from "react-router-dom";

const RecipeSearchItem = ({ recipe, index }) => {

  const { setLoading } = useContext(recipeSearchContext);

  const onClick = (e) => {
    setLoading(true);
  };

  const { name_of_recipe, cuisine, total_time } = recipe;
  return (
    <Fragment>
      <tr>
        <th scope="row">{index}</th>
        <td>
          <Link className="" to={`/showRecipe/${recipe.s_no}`} onClick={onClick}>
            {name_of_recipe !== "" &&
              name_of_recipe.toUpperCase().replace(/\//g, ' / ')}
          </Link>
        </td>
        <td>{cuisine}</td>
        <td>{total_time}</td>
      </tr>
    </Fragment>
  );
};

RecipeSearchItem.propTypes = {
  recipe: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default RecipeSearchItem;
