import React, { useEffect, useContext, Fragment } from 'react'
import Show from './components/showPlant/Show.js'
import Home from './components/pages/Home'
import HowToUse from './components/pages/HowToUse'
import Admin from './components/admin/Admin'
import FAQ from './components/pages/FAQ'
import Contact from './components/pages/Contact'
import About from './components/pages/About'
import Subscribe from './components/pages/subscribe/Subscribe'
import Disclaimer from './components/pages/Disclaimer'
import Terms from './components/pages/Terms'
import Policy from './components/pages/Policy'
import Navbar from './components/layout/Navbar'
import SearchResult from './components/search/searchResult/SearchResult'
import ScrollToTop from './ScrollToTop'
import ShowKarma from './components/showPlant/ShowKarma'
import ShowDiseases from './components/showPlant/ShowDiseases'
import ShowAyurForm from './components/showPlant/ShowAyurForm'
import PrivateRoute from './components/routing/PrivateRoute'
import AdminRoute from './components/routing/AdminRoute'
import ProRoute from './components/routing/ProRoute'
import Search from './components/search/Search'
import Login from './components/auth/Login'
import AuthContext from './contexts/auth/authContext'
import Profile from './components/profile/Profile'
import UpdateProfile from './components/profile/UpdateProfile'
import { Route, Switch } from 'react-router-dom'

/* -------------------------------------------------------------------------- */
import DrugSearch from './components/drugSearch/DrugSearch'
import DrugsSearchResult from './components/drugSearch/drugSearchResult/DrugSearchResult'
import ShowDrug from './components/showDrug/ShowDrug'
import ShowDrugDiseases from './components/showDrug/ShowDrugDiseases'
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
import RecipeSearch from './components/recipeSearch/RecipeSearch'
import RecipesSearchResult from './components/recipeSearch/recipeSearchResult/RecipeSearchResult'
import ShowRecipe from './components/showRecipe/ShowRecipe'
/* -------------------------------------------------------------------------- */




const AppLoadUser = () => {

    const { loadUser } = useContext(AuthContext);

    useEffect(() => {
        loadUser();
        // eslint-disable-next-line
    }, [])

    return (
        <Fragment>
            <Navbar />
            <ScrollToTop />
            <Fragment>
                <Switch>
                    <Route exact path={`/`} component={Home} />
                    <PrivateRoute exact path={`/search`} component={Search} />
                    <PrivateRoute exact path={`/drugSearch`} component={DrugSearch} />
                    <PrivateRoute exact path={`/recipeSearch`} component={RecipeSearch} />
                    <Route exact path={'/login'} component={Login} />
                    <Route exact path={`/contact`} component={Contact} />
                    <Route exact path={`/howto`} component={HowToUse} />
                    <Route exact path={`/faq`} component={FAQ} />
                    <Route exact path={`/about`} component={About} />
                    <Route exact path={`/disclaimer`} component={Disclaimer} />
                    <Route exact path={`/policy`} component={Policy} />
                    <Route exact path={`/terms`} component={Terms} />
                    <ProRoute exact path={`/show/karma/:karma`} component={ShowKarma} />
                    <ProRoute exact path={`/show/diseases/:diseases`} component={ShowDiseases} />
                    <ProRoute exact path={`/show/ayurform/:ayurform`} component={(props) => (<ShowAyurForm key={props.match.params.ayurform} {...props} />)} />
                    <PrivateRoute exact path={`/show/:id`} component={Show} />
                    <PrivateRoute exact path={`/showDrug/:id`} component={ShowDrug} />  
                    <PrivateRoute exact path={`/showDrug/diseases/:diseases`} component={ShowDrugDiseases} />
                    <ProRoute exact path={`/search/result`} component={SearchResult} />
                    <PrivateRoute exact path={`/search/basicResult`} component={SearchResult} />                    
                    <PrivateRoute exact path={`/drugSearch/result`} component={DrugsSearchResult} />                   
                    <PrivateRoute exact path={`/recipeSearch/result`} component={RecipesSearchResult} />  
                    <PrivateRoute exact path={`/showRecipe/:id`} component={ShowRecipe} />
                    
                    <PrivateRoute exact path={`/profile`} component={Profile} />
                    <PrivateRoute exact path={`/UpdateProfile`} component={UpdateProfile} />
                    <PrivateRoute exact path={`/subscribe`} component={Subscribe} />
                    {/* <Route exact path={`/${process.env.REACT_APP_ADMIN_ROUTE}`} component={Admin} /> */}
                    <AdminRoute exact path={`/hiddenRouteForAdmin`} component={Admin} />
                </Switch>
            </Fragment>
        </Fragment>
    )
}

export default AppLoadUser;