import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Dropdown = (props) => {
  const [value, setValue] = useState("");
  const [suggest, setSuggest] = useState([]);

  const { name, setSearch, search } = props;
  const { getDrug, clearDrugs, initialState } = props.context;

  useEffect(() => {
    let mounted = true;
    const retrieve = async () => {
      const res = await axios.get(
        `/backend/drugSearch/autocomplete?col_name=${name}&value=`
      );
      if (mounted) setSuggest(res.data.map((word) => word[0]));
    };
    if (mounted) retrieve();
    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);

  const onClick = () => {
    clearDrugs(search);
    initialState[name] = value;
    getDrug(initialState);
  };

  const onChange = (e) => {
    setValue(e.target.value);
    setSearch({
      ...search,
      [name]: e.target.value,
    });
  };

  return (
    <Fragment>
      <select
        value={value}
        className="btn btn-sm btn-light border-dark w-75"
        onChange={onChange}
      >
        <option value="">Select an option...</option>
        {suggest.map((word, index) => (
          <option key={index} value={word}>
            {word[0].toUpperCase() + word.slice(1, word.length)}
          </option>
        ))}
      </select>
      <span className="">
        <Link
          to={`/drugSearch/result`}
          onClick={onClick}
          className="btn btn-sm btn-secondary"
        >
          Go
        </Link>
      </span>
    </Fragment>
  );
};

export default Dropdown;